import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { FETCH_URL } from "../../../config";
import useTokenManager from "../../../hooks/useTokenManager";

function CustomAutocomplete({
  id,
  label,
  value,
  setValue,
  defaultValue,
  uri,
  customOptions,
}) {
  const [options, setOptions] = useState([]);
  const { getToken } = useTokenManager();

  useEffect(() => {
    if (uri) {
      const jwt = getToken();
      fetch(`${FETCH_URL}${uri}`, { headers:{'Authorization': 'Bearer ' + jwt}})
        .then((response) => response.json())
        .then((d) => {
          // console.info("Entró donde eraa", uri);
          // console.info(d);
          setOptions(d.sort());
        })
        .catch(() => setOptions(["", ""]));
    } else {
      setOptions(customOptions);
    }
  }, []);

  return (
    <Autocomplete
      // disablePortal
      style={{width: 230}}
      defaultValue={defaultValue}
      id={id}
      freeSolo
      label={label}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        // console.info({event, newValue});
        try {
          setValue(newValue?.toUpperCase());
        } catch {
          setValue(newValue);
        }
      }}
      onClose={() => {}}
      sx={{ width: 223 }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          onDragExit={() => {}}
          onChange={(event, newValue) => {
            try {
              setValue(event.target.value?.toUpperCase());
            } catch {
              setValue(event.target.value);
            }
          }}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
