import * as React from 'react';
import Button from '@mui/material/Button';
import InconsistencyHistoryDialog from "./InconsistencyHistoryDialog"

export default function InconsistencyHistory({ open, setOpen, document, year, month }) {  
  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Ver inconsistencias
      </Button>
      <InconsistencyHistoryDialog
        open={open}
        setOpen={setOpen}
        document={document}
        year={year}
        month={month}
      />
    </>
  );
}
