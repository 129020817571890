import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
// import CustomComissionsTable from "../../commons/components/RulesTable/CustomComissionsTable";
import CommisionDetail from "../CommisionDetail/CommisionDetail";
import { UtilsContext } from "../..";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import CustomComissionsTable from "../../commons/components/RulesTable/CustomComissionsTable";
import DownloadIcon from '@mui/icons-material/Download';
import excelIcon from "../../assets/excel.png";
import wordIcon from "../../assets/word.png";
import { useAuth } from "../../hooks/useAuth";

const formatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
});

const trans = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

const trans_opuesto = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};

const currentDate = new Date()

function Commision() {
  const { getToken } = useTokenManager();
  const { enterprise } = useContext(UtilsContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(-1);

  const [month, setMonth] = useState(trans[currentDate.getMonth()]);
  const [year, setYear] = useState(currentDate.getFullYear());

  const [documento, setDocumento] = useState("");

  const { isAdmin } = useAuth();

  // const token = getToken();

  const transformedData = useMemo(
    () => {
      if( data && Array.isArray(data) ) {
        return data.map((user, index) => ({
          id: index,
          period: `${trans[user.month]} de ${user.year}`,
          total_commission: formatter.format(user.total_commission),
          id_document: "CC " + user.employee.id_document,
          name: user.employee.lastname_name,
          charge: user.employee.actual_postition,
          detailedAditionalData: { monthYear: `${user.year}_${user.month}` },
          state: "",
        }))
      }
      else {
        return [];
      }
    },
    [data]
  );

  const fetchExcel = () => {
    const jwt = getToken();
    fetch(
      `${FETCH_URL}${enterprise}-commission/file?month=${trans_opuesto[month]}&year=${year}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Authorization": "Bearer " + jwt
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Comisiones calculadas ${enterprise} ${month} ${year}.xlsx`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const fetchPolicy = () => {
    const jwt = getToken();
    fetch(
      `${FETCH_URL}employees/policy`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Authorization": "Bearer " + jwt
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Política ${enterprise} ${month} ${year}.docx`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const detailedUser = useMemo(() => {
    if (userId >= 0) return data[userId];
    else return null;
  }, [data, userId]);

  const handleBackButton = () => {
    setUserId(-1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setUserId(-1);
    fetchData();
  }, [enterprise]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    const jwt = getToken();
    // console.info("VA A HACER LA PETICIÓN EN COMMISSIONS")
    fetch(
      `${FETCH_URL}${enterprise}-commission?month=${trans_opuesto[month]}&year=${year}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + jwt,
        },
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {

        if (documento !== "") {
          const newData = fetchedData.filter(
            (user) => "" + user.employee.id_document === documento
          );
          // console.info(newData);
          setData(newData);
        } else {
          setData(fetchedData);
        }

        setIsLoading(false);
      })
      .catch((e) => console.error(e));
    

      fetch(`${FETCH_URL}files`, { headers: { 'Authorization': 'Bearer ' + jwt } })
        .then((response) => response.json())
        .then((d) => {
          // console.info("Todos los archivos:");
          // console.info(d);
          const results = d.resultsGmo.subFiles;
          let latest = 1;
          results.forEach(result => {
              if(result.startsWith("comisiones")) {
                const splitted = result.split("-");
                if(splitted[2].startsWith("2023")) {
                  // console.info(splitted);
                  // const month = String.parseInt();
                  latest = splitted[1];
                }
              }
          });
        })
        .catch((e) => console.error(e));
  }, [documento, month, year, getToken, enterprise, data]);

  return (
    <Grid>
      {detailedUser ? (
        <CommisionDetail
          user={detailedUser}
          handleBackButton={handleBackButton}
        />
      ) : (
        <>
          {
            isAdmin
            ? (
                <Grid item>
                  <h1>Comisiones {isAdmin ? "" : `${month.toLowerCase()} ${year}`}</h1>
                </Grid>
              )
            : (
            <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1>Comisiones {`${month.toLowerCase()} ${year}`}</h1>
            </Grid>
            <Grid item>
                <Button
                  aria-label="delete"
                  size="small"
                  onClick={fetchPolicy}
                  variant="outlined"
                  style={{backgroundColor: "#EFFCEF", marginLeft: 20}}
                >
                  <DownloadIcon fontSize="20" />
                  <img src={wordIcon} width="30" />
                  <p style={{marginLeft: 10}}>Última política aplicada</p>
                </Button>
              </Grid>
          </Grid>)}
          {isAdmin ? (<Paper variant="outlined" round style={{ padding: 15 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={5}
              >
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Mes</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={month}
                      label="Mes"
                      style={{ width: 200 }}
                      onChange={(e) => {
                        setMonth(e.target.value);
                      }}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                        <MenuItem value={trans[i]}>{trans[i]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Año</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={year}
                      label="Año"
                      style={{ width: 200 }}
                      onChange={(e) => {
                        setYear(e.target.value);
                      }}
                    >
                      <MenuItem value={"2021"}>2021</MenuItem>
                      <MenuItem value={"2022"}>2022</MenuItem>
                      <MenuItem value={"2023"}>2023</MenuItem>
                      <MenuItem value={"2024"}>2024</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Documento del empleado"
                      variant="outlined"
                      value={documento}
                      onChange={(e) => setDocumento(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={fetchData}>
                    Filtrar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    aria-label="delete"
                    size="small"
                    onClick={fetchExcel}
                    variant="outlined"
                    style={{backgroundColor: "#EFFCEF"}}
                  >
                    <DownloadIcon fontSize="20" />
                    <img src={excelIcon} width="30" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>)
          : <></>}
          <br />
          <CustomComissionsTable
            rows={transformedData}
            onClick={(uid) => setUserId(uid)}
            isLoading={isLoading}
          />
        </>
      )}
    </Grid>
  );
}

export default Commision;
