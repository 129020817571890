import { Grid, Paper } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const managementBossColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "type_of_boss",
    name: "Tipo de Jefe",
    type: "dropdown",
    dropdownType: "typeOfBoss",
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "sales_compliance",
    name: "Cumplimiento de Ventas",
    type: "percentage",
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage",
  },
  {
    field: "customer_conversion",
    name: "Conversión Clientes",
    type: "percentage",
  },
  {
    field: "proposed_activities",
    name: "Tablero de Actividades",
    type: "percentage",
  },
  {
    field: "portafolio",
    name: "Cartera",
    type: "percentage",
  },
  {
    field: "add_product_greater_than",
    name: "Condición Sumaproducto",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

const managementZBossColumns = [
  {
    field: "id",
    name: "Cédula",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "dropdown",
    dropdownType: "position",
  },
  {
    field: "sales_compliance",
    name: "Cumplimiento de Ventas",
    type: "percentage",
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage",
  },
  {
    field: "customer_conversion",
    name: "Conversión Clientes",
    type: "percentage",
  },
  {
    field: "proposed_activities",
    name: "Tablero de Actividades",
    type: "percentage",
  },
  {
    field: "inquiries",
    name: "Consultas",
    type: "percentage",
  },
  {
    field: "add_product_greater_than",
    name: "Condición Sumaproducto",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

function JefeDeTienda() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{width: "100%"}}>
      <Paper
        elevation={3}
        style={{
          padding: 25,
          paddingTop: 0,
          backgroundColor: "#DEEDF6",
        }}
      >
        <div style={{ height: 10 }} />
        <RulesTable
          title="Bonos de gestión"
          columns={managementBossColumns}
          uri="gmo-management-boss"
          downloadUri="gmo-management-boss/file"
        />

        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de gestión Jefe Zonal"
          columns={managementZBossColumns}
          uri="gmo-management-zboss"
          downloadUri="gmo-management-zboss/file"
        />
        <div style={{ height: 10 }} />

        {/*         
        <CustomDialog
          title="Editar bono de gestión"
          open={openGestionDialog}
          handler={() => setOpenGestionDialog(false)}
        >
          <FormControl fullWidth sx={{ m: 1 }}>
            <CurrencyElement />
          </FormControl>
        </CustomDialog> */}
      </Paper>
    </Grid>
  );
}

export default JefeDeTienda;
