import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import UploadArea2 from "./upload-file-component";
import { trans_opuesto } from "./UploadFiles";


const UploadRegularFiles = ({ year, month, setError, setLoading, setSuccess, loading }) => {
    const [files, setFiles] = useState({
        monthly_store: undefined,
        monthly_employee: undefined,
        store: undefined,
        employees: undefined,
    });

    const { getToken } = useTokenManager();

    const handleFileChange = (identifier, value) => {
        setFiles((prevState) => ({ ...prevState, [identifier]: value }));
    };

    // Esta funcion hace toda la lógica para dar respuesta al requerimiento de subir los archivos
    const handleSubmit = async () => {
        setError("");
        setLoading(true);

        // Validaciones de la logica del negocio
        if (!files.monthly_store) {
            setError("El archivo de tiendas mensuales es obligatorio.");
            setLoading(false);
            return;
        }
        if (!files.monthly_store.name.endsWith("xlsx")) {
            setError("Formato de archivo de tiendas mensuales inválido.");
            setLoading(false);
            return;
        }
        if (!files.monthly_employee) {
            setError("El archivo de empleados mensuales es obligatorio.");
            setLoading(false);
            return;
        }
        if (!files.monthly_employee.name.endsWith("xlsx")) {
            setError("Formato de archivo de empleados mensuales inválido.");
            setLoading(false);
            return;
        }
        if (files.store && !files.store.name.endsWith("xlsx")) {
            setError("Formato de archivo de tiendas inválido.");
            setLoading(false);
            return;
        }
        if (files.employees && !files.employees.name.endsWith("xlsx")) {
            setError("Formato de archivo de empleados inválido.");
            setLoading(false);
            return;
        }

        try {
            // Promesas a ejecutar para enviar los archivos
            const promises = [];
            const jwt = getToken();

            // Obligatorias:
            const monthlyStoreData = new FormData();
            monthlyStoreData.append("file", files.monthly_store);
            promises.push(
                fetch(
                    `${FETCH_URL}monthly-store/upload?year=${year}&month=${trans_opuesto[month]}`,
                    {
                        body: monthlyStoreData,
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            "Authorization": "Bearer " + jwt
                        }
                    }
                )
            );

            const monthlyEmployeeData = new FormData();
            monthlyEmployeeData.append("file", files.monthly_employee);
            promises.push(
                fetch(
                    `${FETCH_URL}monthly-employee/upload?year=${year}&month=${trans_opuesto[month]}`,
                    {
                        body: monthlyEmployeeData,
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            "Authorization": "Bearer " + jwt
                        }
                    }
                )
            );

            // Opcionales
            if (files.store) {
                const storeFormData = new FormData();
                storeFormData.append("file", files.store);
                promises.push(
                    fetch(
                        `${FETCH_URL}store/upload?year=${year}&month=${trans_opuesto[month]}`,
                        {
                            body: storeFormData,
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                "Authorization": "Bearer " + jwt
                            }
                        }
                    )
                );
            }

            if (files.employees) {
                const employeesFormData = new FormData();
                employeesFormData.append("file", files.employees);
                promises.push(
                    fetch(
                        `${FETCH_URL}employees/upload?year=${year}&month=${trans_opuesto[month]}`,
                        {
                            body: employeesFormData,
                            method: "POST",
                            mode: "cors",
                            cache: "no-cache",
                            credentials: "same-origin",
                            headers: {
                                "Authorization": "Bearer " + jwt
                            }
                        }
                    )
                );
            }

            const request = await Promise.all(promises);

            const calculateCommissions = () =>
                fetch(`${FETCH_URL}commissions`, {
                    body: JSON.stringify({
                        month: trans_opuesto[month],
                        year: parseInt(year),
                    }),
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + jwt
                    },
                    cache: "no-cache",
                    credentials: "same-origin",
                }).catch((err) => {
                    setError(JSON.stringify(err, Object.getOwnPropertyNames(err)));
                    setSuccess("");
                    setLoading(false);
                });

            calculateCommissions();

            setSuccess(
                "Los archivos se han enviado exitosamente, se está procesando la información y podrá ver los cálculos en la pestaña «Comisiones» una vez finalice este proceso"
            );

            setLoading(false);
        } catch (err) {
            setError(JSON.stringify(err, Object.getOwnPropertyNames(err)));
            setLoading(false);
            setSuccess("");
        }
    };

    return (<Grid container direction="column">
        <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
                <h3 style={{ margin: 0, padding: 0 }}>
                    Datos mensuales de tiendas *
                </h3>
                <UploadArea2
                    hint="archivo de datos mensuales de tiendas"
                    onUpload={handleFileChange}
                    identifier={"monthly_store"}
                />
            </Grid>
            <Grid item xs={12}>
                <h3 style={{ margin: 0, padding: 0 }}>
                    Datos mensuales de empleados *{" "}
                </h3>
                <UploadArea2
                    hint="archivo de datos mensuales empleados"
                    onUpload={handleFileChange}
                    identifier={"monthly_employee"}
                />
            </Grid>
            <Grid item xs={12}>
                <h3 style={{ margin: 0, padding: 0 }}>
                    Datos masivos de tiendas (opcional){" "}
                </h3>
                <UploadArea2
                    hint="archivo de datos mensuales tiendas (opcional)"
                    onUpload={handleFileChange}
                    identifier={"store"}
                />
            </Grid>
            <Grid item xs={12}>
                <h3 style={{ margin: 0, padding: 0 }}>
                    Datos masivos empleados (opcional){" "}
                </h3>
                <UploadArea2
                    hint="archivo de datos masivos de empleados (opcional)"
                    onUpload={handleFileChange}
                    identifier={"employees"}
                />
            </Grid>
        </Grid>
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            {loading ? (
                <p>Cargando...</p>
            ) : (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                >
                    Enviar
                </Button>
            )}
        </Grid>
    </Grid>);
}

export default UploadRegularFiles;
