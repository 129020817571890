import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import useCustomDialogStyles from "./customDialogStyles";

function CustomDialog({
  children,
  title,
  open,
  handler,
  customClass,
  customPaperProps,
  footer,
}) {
  const classes = useCustomDialogStyles();

  const handleClose = () => {
    handler(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      className={customClass}
      PaperProps={{ className: `${classes.customDialog} ${customPaperProps}` }}
      
    >
      <DialogTitle
        disableTypography
        classes={{
          root: `${classes.customDialogTitle} ${
            !title && handleClose && classes.customDialogTitleOnlyCloseButton
          }`,
        }}
      >
        {title && <h1 style={{ color: "black" }}>{title}</h1>}
        {handleClose && (
          <IconButton
            aria-label="close"
            disableRipple
            onClick={handleClose}
            //disabled
          >
            <CloseIcon color="primary" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.customDialogContent }}>
        {children}
      </DialogContent>
      {footer && (
        <DialogActions classes={{ root: classes.customDialogFooter }}>
          {footer}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default CustomDialog;
