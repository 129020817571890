import { createContext, useCallback, useState } from "react";
import { FETCH_URL } from "../config";
import { isTokenValid, parseFullJwt } from "../utils/jwtUtils";

// Context to store all related with auth
// This is a template to understand what is stored on context
export const AuthContext = createContext({
  email: undefined,
  jwt: undefined,
  signup: () => {},
  setPassword: () => {},
  signin: () => {},
  signout: () => {},
  setJwt: () => {},
  isTokenValid: () => {},
  validateLoggedAccount: () => {},
});

export function AuthProvider({ children }) {
  let [email, setEmail] = useState();
  let [isAdmin, setIsAdmin] = useState();
  let [jwt, setJwt] = useState();

  const validateLoggedAccount = useCallback(() => {
    // console.info("Validating logged in account...");
    const storedJwt = localStorage.getItem("access_token");
    // console.info("En el AuthProvider!!!", storedJwt);
    // if (isTokenValid(storedJwt)) {
    // TODO check this
    if (storedJwt) {
      setJwt(storedJwt);
      const parsedUser = parseFullJwt(storedJwt);
      setEmail(parsedUser["username"]);
      setIsAdmin(parsedUser["roles"].includes("admin"));
      //setIsAdmin(false);

      // console.info(
      //   "Usuario logueado anteriormente, token obtenido del session storage",
      //   {
      //     storedJwt,
      //     email: parsedUser["username"],
      //     isAdmin: parsedUser["roles"].includes("admin"),
      //   }
      // );
      return true;
    } else {
      localStorage.removeItem("access_token");
      return false;
    }
  }, [localStorage]);

  let signin = (data, onSuccess, onFailure, setAssignPasswordView) => {
    fetch(`${FETCH_URL}auth/login`, {
      body: JSON.stringify({
        username: data.email,
        password: data.password,
      }),
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      cache: "no-cache",
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.status === 401) {
          response.json().then((body) => {
            onFailure(body.message);
            if (body.message === "No se ha cambiado la contraseña inicial") {
              setAssignPasswordView(true);
            }
          });
        } else {
          response.json().then((res) => {
            if (res.access_token) {
              localStorage.setItem("access_token", res.access_token);
              setJwt(res.access_token);
              const parsedUser = parseFullJwt(res.access_token);
              setEmail(parsedUser["username"]);
              setIsAdmin(parsedUser["roles"].includes("admin"));
              // console.info("Usuario autenticado correctamente!!!", {
              //   jwt: res.access_token,
              //   email: parsedUser["username"],
              //   isAdmin: parsedUser["roles"].includes("admin"),
              // });
              onSuccess();
            } else {
              onFailure();
            }
          });
        }
      })
      .catch((err) => {
        onFailure(JSON.stringify(err, Object.getOwnPropertyNames(err)));
      });
  };

  let signout = (callback) => {
    setEmail(null);
    setJwt(null);
    localStorage.removeItem("access_token");
    window.location.reload();
    if (callback) { callback() };
  };

  let value = {
    jwt,
    setJwt,
    email,
    isAdmin,
    signin,
    signout,
    isTokenValid,
    validateLoggedAccount,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
