
const _capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);


export const translate = (enterprise, key, toReplace) => {

    if (!toReplace) return key;
    if (toReplace.startsWith("undefined")) return key;

    let parsedToReplace = toReplace?.toLowerCase();

    // ===============
    // Cargos de GMO
    // ===============
    if (enterprise === "gmo")
        switch (key) {
            // Cargo gmo_sales_advisor
            case "Comisión por ventas":
                return `Comisión por ${parsedToReplace}`;

            case "Ventas":
                return _capitalize(parsedToReplace);

            case "Meta de ventas":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento":
                return "Porcentaje de cumplimiento";

            case "Comisión de ventas del asesor":
                return `Comisión de ${parsedToReplace}`;

            // Cargo gmo-performance-advisor
            case "Comisión por rendimiento de tienda":
                let rendimientoSplitted = parsedToReplace.split("|");
                return `Comisión por ${rendimientoSplitted[0]} y ${rendimientoSplitted[1]}`;

            case "Porcentaje de cumplimiento de ventas":
                return `Porcentaje de cumplimiento de ${parsedToReplace}`;

            case "Porcentaje de ventas de la tienda":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de mermas admitidas":
                return "Porcentaje de mermas admitidas";

            case "Bono de rendimiento de tienda":
                return `Bono`;

            // Cargo gmo-management-boss
            case "Comisión por bono de gestión":
                return `Comisión por ${parsedToReplace}`;

            case "Bono de rendimiento del jefe":
                return `${parsedToReplace} del Jefe`;

            case "Ventas de la tienda":
                return `Venta de ${parsedToReplace}`;

            case "Meta de ventas de la tienda":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas convertidas":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Mermas":
                return `Porcentaje de ${parsedToReplace}`;

            case "Mermas convertidas":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Conversión":
                return `${parsedToReplace}`;

            case "Meta de conversión":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de conversión o clientes (convertido)":
                return;

            case "Actividades propuestas":
                return `${parsedToReplace} propuestas`;

            case "Actividades completas":
                return `${parsedToReplace} completas`;

            case "Porcentaje de cumplimiento de actividades":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de actividades (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Venta facturada":
                return `${parsedToReplace} (Venta facturada)`;

            case "Venta comercial":
                return `${parsedToReplace} (Venta comercial)`;

            case "Porcentaje de cumplimiento de cartera":
                return `Cumplimiento de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de cartera (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            // Cargo gmo_management_zboss
            case "Comisión por bono de gestión":
                return `Comisión por ${parsedToReplace}`;

            case "Bono de rendimiento del jefe":
                return `${parsedToReplace} del Jefe`;

            case "Ventas de la tienda":
                return `Venta de ${parsedToReplace}`;

            case "Meta de ventas de la tienda":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas convertidas":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Mermas":
                return `Porcentaje de ${parsedToReplace}`;

            case "Mermas convertidas":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Conversión":
                return `${parsedToReplace}`;

            case "Meta de conversión":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de conversión o clientes (convertido)":
                return;

            case "Actividades propuestas":
                parsedToReplace = parsedToReplace.toUpperCase();
                return `${parsedToReplace} propuestas`;

            case "Actividades completas":
                parsedToReplace = parsedToReplace.toUpperCase();
                return `${parsedToReplace} completas`;

            case "Porcentaje de cumplimiento de actividades":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de actividades (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Venta facturada":
                return `${parsedToReplace} (Venta facturada)`;

            case "Venta comercial":
                return `${parsedToReplace} (Venta comercial)`;

            case "Porcentaje de cumplimiento de cartera":
                return `Cumplimiento de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de cartera (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Consultas":
                return `${parsedToReplace}`;

            case "Meta consultas":
                return `Meta ${parsedToReplace}`;

            case "Porcentaje de cumplimiento consultas":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento consultas (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            // Cargo gmo_inquiries_optometrist2022
            case "Comisión por consultas de optómetra":
                let consultasSplitted = parsedToReplace.split("|");
                return `Comisión por ${consultasSplitted[0]} y ${consultasSplitted[1]} de optómetra`;

            case "Consultas de optómetra":
                return `${parsedToReplace} de optómetra`;

            case "Consultas según tienda":
                return `Consultas por tienda`;

            case "Consultas con compra":
                return `${parsedToReplace}`;

            case "Consultas sin compra":
                return `${parsedToReplace}`;

            case "Clúster":
                return "Clúster";

            case "storeMoreTime":
                return `Tienda en la que estuvo mayor tiempo`;

            case "sales_compliance_percentage":
                return `Cumplimiento de la tienda`;
            
            // New requirements
            case "Lentes de contacto":
                return toReplace;
            
            case "Lentes progresivos":
                return toReplace;

            // Cargo gmo_performance_optometrist
            case "Comisión por rendimiento de optómetra":
                return `Comisión por rendimiento`;

            case "Bono por rendimiento de optómetra":
                return `Bono por rendimiento de optómetra`;

            case "Tiendas":
                return `Tiendas`;

            case "Ventas de la tienda":
                return `Ventas de la tienda`;

            case "Meta de la tienda":
                return `Meta de la tienda`;

            case "Porcentaje de ventas de la tienda":
                return `Porcentaje de ${parsedToReplace}`;

            // Cargo gmo_performance_optometrist2022  FALTA VISUAL
            case "optometrist_bonus_goal":
                return `${parsedToReplace}`;

            case "clusterLabel":
                return `${parsedToReplace}`;

            case "storeMoreTime":
                return `Tienda de mayor tiempo`;

            case "sales_compliance_percentage":
                return `Porcentaje de cumplimiento de ventas`;

            // Cargo gmo_management_boss_optometrist2022 FALTA VISUAL
            case "management_boss_optometrist_bonus":
                return `${parsedToReplace}`;

            case "clusterLabel":
                return `${parsedToReplace}`;

            case "storeMoreTime":
                return `Tienda de mayor tiempo`;

            case "sales_compliance_percentage":
                return `Porcentaje de cumplimiento de ventas`;

            case "conversion":
                return `${parsedToReplace}`;

            case "conversion_goal":
                return `Meta ${parsedToReplace}`;

            case "invoiced_sale":
                return `Venta facturada`;

            case "commercial_sale":
                return `Venta Comercial`;

            case "portafolio_percentage":
                return `Porcentaje ${parsedToReplace}`;

            case "portafolio_percentage_converted":
                return `Porcentaje ${parsedToReplace} convertido`;

            case "Comisión por bono de gestión":
                return `Comisión por ${parsedToReplace ?? "bono de gestión"}`;

            default:
                return key;
        }

    // =================
    // Cargos de RayBan
    // =================

    else if (enterprise === "ray")
        switch (key) {
            // Cargo ray_sales_advisor
            case "Comisión por ventas":
                return `Comisión por ${parsedToReplace}`;

            case "Ventas":
                return `Ventas de ${parsedToReplace}`;

            case "Meta de ventas":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento":
                return `Porcentaje de cumplimiento`;

            case "Comisión de ventas del asesor":
                return `Comisión de ${parsedToReplace}`;

            // Cargo ray_performance_advisor
            case "Comisión por rendimiento de tienda":
                return `Comisión por ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de ventas":
                return `Porcentaje de cumplimiento de ${parsedToReplace}`;

            case "Porcentaje de ventas de la tienda":
                return `% de Cumplimiento de ${parsedToReplace}`;

            case "Bono de rendimiento de tienda":
                return `Bono`;

            // Cargo ray_management_boss
            // Cargo ray_management_boss
            case "Comisión por bono de gestión":
                return `Comisión por ${parsedToReplace}`;

            case "Bono de rendimiento del jefe":
                return `${parsedToReplace} del Jefe`;

            case "Ventas de la tienda":
                return `Venta de ${parsedToReplace}`;

            case "Meta de ventas de la tienda":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas convertidas":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Conversión":
                return `${parsedToReplace}`;

            case "Meta de conversión":
                return `Meta de ${parsedToReplace}`;

            case "Porcentaje de conversión o clientes (convertido)":
                return;

            case "Actividades propuestas":
                return `${parsedToReplace} propuestas`;

            case "Actividades completas":
                return `${parsedToReplace} completas`;

            case "Porcentaje de cumplimiento de actividades":
                return `Porcentaje de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de actividades (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            case "Venta facturada":
                return `${parsedToReplace} (Venta facturada)`;

            case "Venta comercial":
                return `${parsedToReplace} (Venta comercial)`;

            case "Porcentaje de cumplimiento de cartera":
                return `Cumplimiento de ${parsedToReplace}`;

            case "Porcentaje de cumplimiento de cartera (convertido)":
                return `Porcentaje convertido de ${parsedToReplace}`;

            // cargo ray_inquiries_optometrist2022
            case "Comisión por consultas de optómetra":
                let optometraSplitted = parsedToReplace.split("|");
                return `Comisión por ${optometraSplitted[0]} y ${optometraSplitted[1]} de optómetra`;

            case "Consultas de optómetra":
                return `${parsedToReplace} de optómetra`;

            case "Consultas según tienda":
                return `Consultas por tienda`;

            case "Consultas con compra":
                return `${parsedToReplace}`;

            case "Consultas sin compra":
                return `${parsedToReplace}`;

            // Cargo ray_performance_optometrist2022 FALTA VISUAL
            case "Comisión por rendimiento de optómetra":
                return;

            case "Bono por rendimiento de optómetra":
                return;

            // Cargo ray_performance_optometrist
            case "Comisión por rendimiento de optómetra":
                return `Comisión por rendimiento`;

            case "Bono por rendimiento de optómetra":
                return `Bono por rendimiento de optómetra`;

            case "Tiendas":
                return `Tiendas`;

            case "Ventas de la tienda":
                return `Ventas de la tienda`;

            case "Meta de la tienda":
                return `Meta de la tienda`;

            case "Porcentaje de ventas de la tienda":
                return `Porcentaje de${parsedToReplace}`;

            // Cargo ray_performance_optometrist2022 FALTA VISUAL
            case "Meta de bono de optómetra":
                return parsedToReplace;

            case "Clúster":
                return parsedToReplace;

            case "Tienda de mayor tiempo":
                return "Tienda de mayor tiempo";

            case "Porcentaje de cumplimiento de ventas":
                return "Porcentaje de cumplimiento de ventas";

            // Cargo ray_management_boss_optometrist2022 FALTA VISUAL

            // Cargo gmo_performance_optometrist2022  FALTA VISUAL
            case "optometrist_bonus_goal":
                return `${parsedToReplace}`;

            case "clusterLabel":
                return `${parsedToReplace}`;

            case "storeMoreTime":
                return `Tienda de mayor tiempo`;

            case "sales_compliance_percentage":
                return `Porcentaje de cumplimiento de ventas`;

            // Cargo gmo_management_boss_optometrist2022 FALTA VISUAL
            case "management_boss_optometrist_bonus":
                return `${parsedToReplace}`;

            case "clusterLabel":
                return `${parsedToReplace}`;

            case "storeMoreTime":
                return `Tienda de mayor tiempo`;

            case "sales_compliance_percentage":
                return `Porcentaje de cumplimiento de ventas`;

            case "conversion":
                return `${parsedToReplace}`;

            case "conversion_goal":
                return `Meta ${parsedToReplace}`;

            case "invoiced_sale":
                return `Venta facturada`;

            case "commercial_sale":
                return `Venta Comercial`;

            case "portafolio_percentage":
                return `Porcentaje ${parsedToReplace}`;

            case "portafolio_percentage_converted":
                return `Porcentaje ${parsedToReplace} convertido`;

            default:
                return key;
        }

    // ========================
    // Cargos de Sunglass Hut
    // ========================

    else if (enterprise === "sgh")
        switch (key) {

            // Cargo sgh_sales_advisor
            case "Comisión de ventas del asesor":
                return `Comisión de ventas del asesor`;

            case "Ventas de la tienda":
                return `Ventas de la tienda`;

            case "Meta de la tienda":
                return `Meta de la tienda`;


            // Cargo sgh_sales_advisor_extended
            case "Comisión de ventas del asesor":
                return `Comisión de ventas del asesor`;

            case "Ventas":
                return `Ventas de la tienda`;

            case "Meta de ventas":
                return `Meta de la tienda`;

            case "Porcentaje de cumplimiento (personal)":
                return `Porcentaje de cumplimiento (personal)`;

            case "Porcentaje de cumplimiento (tienda)":
                return `Porcentaje de cumplimiento (tienda)`;

            // Cargo sgh_management_boss
            case "boss_management_commission":
                return `Bono de rendimiento Jefe`;

            case "Ventas de la tienda":
                return `Ventas de la tienda`;

            case "Meta de la tienda":
                return `Meta de la tienda`;

            case "Porcentaje de cumplimiento de las ventas":
                return `${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas (convertidas)":
                return `${parsedToReplace} (convertidas)`;

            case "Unidades":
                return `Unidades`;

            case "Meta de unidades":
                return `Meta de unidades`;

            case "Porcentaje de unidades":
                return `${parsedToReplace}`;

            case "Porcentaje de unidades (convertido)":
                return `${parsedToReplace} (convertido)`;

            // Cargo sgh_simple_management_boss
            case "Comisión de ventas de supervisor":
                return `Comisión de ventas de supervisor`;

            case "Ventas de la tienda":
                return `Ventas de la tienda`;

            case "Meta de la tienda":
                return `Meta de la tienda`;

            case "Porcentaje de cumplimiento de las ventas":
                return `${parsedToReplace}`;

            case "Porcentaje de cumplimiento de las ventas (convertidas)":
                return `${parsedToReplace} (convertidas)`;

            case "Unidades":
                return `Unidades`;

            case "Meta de unidades":
                return `Meta de unidades`;

            case "Porcentaje de unidades":
                return `${parsedToReplace}`;

            case "Porcentaje de unidades (convertido)":
                return `${parsedToReplace} (convertido)`;

            default:
                return key;
        }

    else return key;
}
