// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useMemo } from "react";

let colombianPeso = Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  minimumSignificantDigits: 1
});

const amount_translations = {
  "advisor_sales_commission": "Ventas",
  "advisor_performance_bonus": "Rendimiento",
  "boss_performance_bonus": "Rendimiento",
  "optometrist_consultations": "Consultas",
  "management_advisor_inst": "Asesor Inst",
  "optometrist_consultations2022": "Consultas",
  "optometrist_consultations2022_bonus": "Bono consultas",
  "optometrist_bonus_goal": "Bono de meta",
  "sales_compliance_percentage": "Cumplimiento",
  "optometrist_consultations2022": "Consultas",
  "optometrist_bonus_goal": "Bono de meta",
  "management_boss_optometrist_bonus": "Bono jefe optómetra",
  "boss_zonal_management_commission": "Gestión",
  "boss_simple_management_commission": "Ventas supervisor",
  "boss_cluster_bonus": "Bono de clúster",
  "boss_cluster_goal_bonus": "Bono meta",
  "boss_cluster_operating_bonus": "Bono operativo",
  "advisor_performance_store": "Bono meta tienda"
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const PieChart = ({ data }) => {

  const dataConverted = useMemo(() => {
    // console.info("En PieChart!!!");
    // console.info(data);
    const parsedData = [];

    let i = 0;
    const keys = Object.keys(amount_translations);

    while (i < keys.length) {
      let key = keys[i];
      if (data[key] > 0) {
        parsedData.push({
          "id": amount_translations[key],
          // "label": amount_translations[key],
          "value": Math.round(data[key])
        });
      }
      i++;
    }

    // console.info(parsedData);
    return parsedData;
  }, [data]);

  function displayTooltip(e) {
    let tooltipInfo = e.datum.data;
    return <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      {`${tooltipInfo.id}: ${colombianPeso.format(tooltipInfo.value)}`}
    </div>;
  }

  return (
    <ResponsivePie
      data={dataConverted}
      margin={{ top: 0, right: 70, bottom: 0, left: 100 }}
      innerRadius={0}
      padAngle={0.8}
      cornerRadius={1}
      activeOuterRadiusOffset={10}
      borderWidth={2}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={1}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={30}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
      tooltip={displayTooltip}
      valueFormat={(string) => colombianPeso.format(string)}
      legends={[
        {
          anchor: "top",
          direction: "column",
          justify: false,
          translateX: 0,
          translateY: 8,
          itemsSpacing: 0,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: "#999",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 10,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />);
}

export default PieChart;
