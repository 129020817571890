import { Button, Grid, Hidden, Paper, Typography } from "@material-ui/core";
import storeIcon from "../../assets/store.png";

const moneyFormatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
});

const formatValue = (type, value, showNa=true) => {
  switch (type) {
    case "percentage":
      return value
        ? (parseFloat(value) * 100).toFixed(2) + "%"
        : showNa ? "N/A" : "0.00%";
    case "money":
      return value ? moneyFormatter.format(value) : showNa ? "N/A" : "$ 0,00";
    default:
      return value;
  }
};

const StoreCard = ({ store, isSgh, isSghAdvisorExtended }) => {
  const getDetailsToDisplay = () => {
    if(!isSgh && !isSghAdvisorExtended) {
      return [
        {
          title: "Ventas de la tienda",
          value: store.monthlyStores[0].store_sales,
          valueType: "money",
        },
        {
          title: "Meta de ventas de la tienda",
          value: store.monthlyStores[0].store_sales_goal,
          valueType: "money",
        },
        {
          title: "Porcentaje de cumplimiento de las ventas",
          value: store.monthlyStores[0].sales_compliance_percentage,
          valueType: "percentage",
        },
        {
          title: "Mermas",
          value: store.monthlyStores[0].mermas,
          valueType: "percentage",
        },
        {
          title: "Mermas convertidas",
          value: store.monthlyStores[0].mermas_converted_percentage,
          valueType: "percentage",
        },
        {
          title:
            store.monthlyStores[0].conversion !== 0
              ? "Conversión"
              : "Clientes",
          value:
            store.monthlyStores[0].conversion !== 0
              ? store.monthlyStores[0].conversion
              : store.monthlyStores[0].customer,
          valueType: "percentage",
        },
        {
          title:
            store.monthlyStores[0].conversion !== 0
              ? "Meta de conversión"
              : "Meta de clientes",
          value:
            store.monthlyStores[0].conversion !== 0
              ? store.monthlyStores[0].conversion_goal
              : store.monthlyStores[0].customer_goal,
          valueType: "percentage",
        },
        {
          title: "Porcentaje de conversión o clientes (convertido):",
          value:
            store.monthlyStores[0].customer_conversion_percentage_converted,
          valueType: "percentage",
        },
        {
          title: "Actividades propuestas",
          value: store.monthlyStores[0].proposed_activities,
        },
        {
          title: "Actividades completas",
          value: store.monthlyStores[0].completed_activities,
        },
        {
          title: "Porcentaje de cumplimiento de actividades",
          value: store.monthlyStores[0].proposed_activities_percentage,
          valueType: "percentage",
        },
        {
          title: "Porcentaje de cumplimiento de actividades (convertido)",
          value:
            store.monthlyStores[0].proposed_activities_percentage_converted,
          valueType: "percentage",
        },
        {
          title: "Venta facturada",
          value: store.monthlyStores[0].invoiced_sale,
          valueType: "money",
        },
        {
          title: "Venta comercial",
          value: store.monthlyStores[0].commercial_sale,
          valueType: "money",
        },
        {
          title: "Porcentaje de cumplimiento de cartera",
          value: store.monthlyStores[0].portafolio_percentage,
          valueType: "percentage",
        },
        {
          title: "Porcentaje de cumplimiento de cartera (convertido)",
          value: store.monthlyStores[0].portafolio_percentage_converted,
          valueType: "percentage",
        },
      ]
    }
  else if(isSgh) {
    return [
      {
        title: "Ventas de la tienda",
        value: store.monthlyStores[0].store_sales,
        valueType: "money",
        showNa: false
      },
      {
        title: "Meta de ventas de la tienda",
        value: store.monthlyStores[0].store_sales_goal,
        valueType: "money",
        showNa: false
      },
      {
        title: "Porcentaje de cumplimiento de las ventas",
        value: store.monthlyStores[0].sales_compliance_percentage,
        valueType: "percentage",
        showNa: false
      },
      {
        title: "Porcentaje de cumplimiento de las ventas (convertidas)",
        value: store.monthlyStores[0].sales_compliance_percentage_converted,
        valueType: "percentage",
        showNa: false
      },
      {
        title: "Unidades",
        value: store.monthlyStores[0].sgh_units,
        showNa: false
      },
      {
        title: "Meta de unidades",
        value: store.monthlyStores[0].sgh_units_goal,
        showNa: false
      },
      {
        title: "Porcentaje de unidades",
        value: store.monthlyStores[0].sgh_units_percentage,
        valueType: "percentage",
        showNa: false
      },
      {
        title: "Porcentaje de unidades (convertido)",
        value: store.monthlyStores[0].sgh_units_percentage_converted,
        valueType: "percentage",
        showNa: false
      },
    ];
  }
else if( isSghAdvisorExtended ) {
    return [
      {
        title: "Ventas",
        value: store?.sales,
        valueType: "money",
        showNa: false
      },
      {
        title: "Meta de ventas",
        value: store?.sales_goal,
        valueType: "money",
        showNa: false
      },
      {
        title: "Porcentaje de cumplimiento (personal)",
        value: store?.personal_sales_compliance_percentage,
        valueType: "percentage",
        showNa: false
      },
      {
        title: "Porcentaje de cumplimiento (tienda)",
        value: store?.store_sales_compliance_percentage,
        valueType: "percentage",
        showNa: false
      },
    ];
  }
}

const detailsToDisplay = getDetailsToDisplay();
  return (
    <Paper
      style={{
        padding: 15,
        backgroundColor: "#F1F1F1",
        width: "100%",
        marginTop: 20
      }}
      elevation={3}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={9}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              <img src={storeIcon} width={"100px"} height="auto" alt="" />
            </Grid>
            <Typography variant="h5" style={{ fontWeight: 450 }}>
              Tienda {store.gesti_code} {store.city}
            </Typography>
          </Grid>
          {detailsToDisplay.map((column) => (
            <>
              <div
                style={{
                  width: 400,
                  display: "inline-block",
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    marginTop: 6,
                    display: "inline",
                  }}
                >
                  <strong style={{ color: "#201B1C" }}>{column.title} </strong>
                </p>
              </div>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginTop: 6,
                  display: "inline",
                }}
              >
                {formatValue(column.valueType, column.value, column.showNa)}
              </p>
              <br />
            </>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StoreCard;
