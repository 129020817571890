import { CssBaseline } from "@material-ui/core";
import { useAuth } from "../../../hooks/useAuth";
import HomeLayout from "../AdminLayout/HomeLayout";
import NavBar from "../../../commons/components/NavBar/NavBar";
import { Redirect } from "react-router-dom";

export default function ComissionsWrapper({ children }) {
  const { validateLoggedAccount, jwt, isAdmin } = useAuth();

  if (!validateLoggedAccount()) {
    return <Redirect to={"/login"} />;
  }

  if (isAdmin) {
    return <HomeLayout>{children}</HomeLayout>;
  }

  if (jwt && isAdmin === false)
    return (
      <div>
        <CssBaseline />
        <NavBar />
        <div style={{ padding: "50px" }}>{children}</div>
      </div>
    );
  
  return <p>Cargando...</p>
}
