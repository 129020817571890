import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

export const COLOR_DARK_BLUE = "#3E2B2E";
export const COLOR_YELLOW = "#DDFB2F";
export const COLOR_FLUORESCENT_YELLOW = "#DEF947";
export const COLOR_LIGHT_YELLOW = "#E6F96C";
export const COLOR_BLUE = "#7BAFD4";
export const COLOR_LIGHT_BLUE = "#52A8E4";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_LIGHT_GRAY = "#F6F7FB";
export const COLOR_DARK_GRAY = "#5F6E79";
export const COLOR_GREEN = "#48C75B";
export const COLOR_DARK_GREEN = "#3b883e";
export const COLOR_DARK_RED = "#923131";
export const COLOR_ORANGE = "#ff9800";
export const COLOR_DARK_ORANGE = "#905601";

// A custom theme for this redux
export const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_BLUE,
    },
    secondary: {
      main: "#978C87",
    },
    error: {
      main: red.A700,
      dark: COLOR_DARK_RED,
    },
    background: {
      default: COLOR_WHITE,
      main: COLOR_LIGHT_GRAY,
    },
    gray: {
      light: COLOR_LIGHT_GRAY,
      dark: COLOR_DARK_GRAY,
    },
    link: {
      main: COLOR_LIGHT_BLUE,
    },
    green: {
      main: COLOR_GREEN,
      dark: COLOR_DARK_GREEN,
    },
    orange: {
      main: COLOR_ORANGE,
      dark: COLOR_DARK_ORANGE,
    },
    yellow: {
      main: COLOR_YELLOW,
    },
    attention: {
      main: COLOR_FLUORESCENT_YELLOW,
      light: COLOR_LIGHT_YELLOW,
    },
  },
});
