import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CommisionDetailCard from "./CommisionDetailCard";
import StoreCard from "./StoreCard";
import ConsultationDetailCard from "../ConsultationDetailCard/ConsultationDetailCard";
import { FETCH_URL } from "../../config";
import { Button, Grid, Paper } from "@material-ui/core";
import useTokenManager from "../../hooks/useTokenManager";

const specificColors = [
    "#EFFCEF",
    "#F9D1D6",
    "#F9D1D6",
    "#EFFCEF",
    "#EFFCEF",
    "#F9D1D6",
    "#F9D1D6",
    "#EFFCEF",
    "#EFFCEF",
    "#F9D1D6",
    "#F9D1D6",
    "#EFFCEF",
];

const CommisionDetailSGH = ({ user }) => {
    const [salesAdvisorExtendedInfo, setSalesAdvisorExtendedInfo] = useState();
    const { getToken } = useTokenManager();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user.sgh_rules_for_charge_id?.sgh_sales_advisor_extended) {
            setIsLoading(true);
            const jwt = getToken();
            fetch(
                `${FETCH_URL}sgh-sales?month=${user.month}&year=${user.year}&idEmpleado=${user.employee.id_document}`,
                {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization:
                            "Bearer " + jwt,
                    },
                }
            )
                .then((response) => response.json())
                .then((fetchedData) => {
                    // console.info("Detalle de ventas", fetchedData);
                    setSalesAdvisorExtendedInfo(fetchedData);
                    setIsLoading(false);
                });
        }
    }, []);

    return (<>
        {/* BLOQUE DE COMISIÓN POR VENTAS DEL ASESOR */}
        {user.sgh_rules_for_charge_id.sgh_sales_advisor || user.sgh_rules_for_charge_id.sgh_sales_advisor_extended ? (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">
                            <strong>Comisión por ventas</strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <CommisionDetailCard
                                        title={"Comisión de ventas del asesor"}
                                        color={"#F9D1D6"}
                                        value={user.advisor_sales_commission}
                                        valueType={"money"}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <></>
        )}

        {/* BLOQUE DE COMISIÓN SUPERVISOR DE TIENDA  */}
        {user.sgh_rules_for_charge_id?.sgh_simple_management_boss && (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <CommisionDetailCard
                                        title={"Comisión de ventas de supervisor"}
                                        color={"#F9D1D6"}
                                        value={user.boss_simple_management_commission ?? 0}
                                        valueType={"money"}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        )}

        {/* BLOQUE DE Comisión por rendimiento de tienda para el asesor: */}
        {/* {user?.sgh_rules_for_charge_id?.gmo_performance_advisor ? (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 50 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">
                            <strong>Comisión por rendimiento de tienda</strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{ flexGrow: 1 }} style={{ marginTop: 20 }}>
                            <Grid container spacing={2}>
                                {[
                                    {
                                        title: "Porcentaje de cumplimiento de ventas",
                                        value:
                                            user.employee.monthlyEmployees[0]
                                                .personal_sales_compliance_percentage,
                                        valueType: "percentage",
                                    },
                                    {
                                        title: "Porcentaje de ventas de la tienda",
                                        value:
                                            user.employee.store_codes[0].monthlyStores[0]
                                                .sales_compliance_percentage,
                                        valueType: "percentage",
                                    },
                                    {
                                        title: "Porcentaje de mermas admitidas",
                                        value:
                                            user.employee.store_codes[0].monthlyStores[0]
                                                .mermas_converted_percentage,
                                        valueType: "percentage",
                                    },
                                    {
                                        title: "Bono de rendimiento de tienda",
                                        value: user.advisor_performance_bonus,
                                        valueType: "money",
                                    },
                                ].map(({ title, value, valueType }, index) => (
                                    <Grid container item xs={3}>
                                        <CommisionDetailCard
                                            title={title}
                                            color={index % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                                            value={value}
                                            valueType={valueType}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <></>
        )} */}

        {/* BLOQUE DE COMISIÓN DEL sgh_management_zonal_boss: */}
        {user.sgh_rules_for_charge_id.sgh_management_zonal_boss ? (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 0 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h5">
                            <strong>Comisión por bono de gestión</strong>
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: 15, marginBottom: 30 }}>
                        <CommisionDetailCard
                            title={"Bono de gestión del jefe"}
                            color={"#F9D1D6"}
                            value={user.boss_zonal_management_commission}
                            valueType={"money"}
                        />
                    </Grid>
                    <Grid container xs={12} style={{ marginBottom: 100 }}>
                        {user.employee.store_codes.map((store) => (
                            <StoreCard store={store} isSgh={true} />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        ) : (
            <></>
        )}

        {/* BLOQUE DE Comisión por consultas de optómetra: */}
        {user?.sgh_rules_for_charge_id?.sgh_sales_advisor_extended ? (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 0 }}
            >
                <Grid
                    container
                    xs={12}
                    style={{ marginBottom: 15 }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h6" style={{ marginTop: 15 }}>
                        Detalle de ventas por tienda:
                    </Typography>
                </Grid>
                <Grid container xs={12} style={{ marginBottom: 10 }}></Grid>
                <Grid
                    container
                    xs={12}
                    style={{ marginBottom: 100 }}
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    {isLoading ? (
                        <p>Cargando...</p>
                    ) : (
                        salesAdvisorExtendedInfo?.map((consultation, i) => (
                            <Grid container xs={12}>
                                <StoreCard
                                    isSghAdvisorExtended={true}
                                    store={{
                                        ...consultation,
                                        gesti_code: consultation.store.gesti_code,
                                        city: consultation.store.city
                                    }}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        ) : (
            <></>
        )}

        {/* BLOQUE DE TIENDAS PARA JEFE DE TIENDAS */}
        {(user?.sgh_rules_for_charge_id?.sgh_management_boss || user?.sgh_management_boss?.sgh_simple_management_boss) &&
            (<Grid container xs={12} style={{ marginBottom: 100 }}>
                {user.employee.store_codes.map((store) => (
                    <StoreCard store={store} isSgh={true} />
                ))}
            </Grid>)}

        {/* BLOQUE DE Comisión por rendimiento de optómetra: */}
        {user?.sgh_rules_for_charge_id?.gmo_performance_optometrist ? (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 0 }}
            >
                <Grid
                    container
                    style={{ marginBottom: 10 }}
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <Typography variant="h5">
                        <strong>Comisión por rendimiento de optómetra</strong>
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    style={{ marginBottom: 15 }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid container item xs={4}>
                        <CommisionDetailCard
                            title={"Bono por rendimiento de optómetra"}
                            color={0 % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                            value={user.optometrist_performance}
                            valueType={"money"}
                        />
                    </Grid>
                    <Typography variant="h6" style={{ marginTop: 15 }}>
                        Tiendas:
                    </Typography>
                </Grid>
                <Grid container xs={12} style={{ marginBottom: 10 }}></Grid>
                {user.employee.store_codes[0].monthlyStores?.map(
                    (store, i) => (
                        <Grid
                            container
                            xs={12}
                            style={{ marginBottom: 100 }}
                            spacing={3}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid container item xs={4}>
                                <CommisionDetailCard
                                    title={"Ventas de la tienda"}
                                    color={i % 2 === 0 ? "#EFFCEF" : "#F9D1D6"}
                                    value={store.store_sales}
                                    valueType={"money"}
                                />
                            </Grid>
                            <Grid container item xs={4}>
                                <CommisionDetailCard
                                    title={"Meta de la tienda"}
                                    color={i % 2 === 0 ? "#F9D1D6" : "#EFFCEF"}
                                    value={store.store_sales_goal}
                                    valueType={"money"}
                                />
                            </Grid>
                            <Grid container item xs={4}>
                                <CommisionDetailCard
                                    title={"Porcentaje de ventas de la tienda"}
                                    color={i % 2 === 0 ? "#EFFCEF" : "#F9D1D6"}
                                    value={store.sales_compliance_percentage}
                                    valueType={"percentage"}
                                />
                            </Grid>
                        </Grid>
                    )
                )}
            </Grid>
        ) : (
            <></>
        )}
    </>);
}


export default CommisionDetailSGH;