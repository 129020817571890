import React, { useContext, useState } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import SettingsIcon from "@material-ui/icons/Settings";
import useHomeLayoutStyles from "./useHomeLayout";
import { Button, Grid, makeStyles } from "@material-ui/core";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import logoGmo from "../../../assets/gmo_v_col_rgb.png";
import logoSunglass from "../../../assets/sunglass-hut.png";
import logoRayban from "../../../assets/rayban.jpg";
import logoLafam from "../../../assets/lafam_logo.png";

import UploadFileIcon from "@material-ui/icons/CloudUpload";
import StoreIcon from "@mui/icons-material/Store";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import BadgeIcon from "@mui/icons-material/Badge";
import { UtilsContext } from "../../..";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

const useDrawerPaper = makeStyles(() => ({
  root: { background: ({ color }) => color },
}));

function SwitchAccount() {
  const { signout } = useAuth();
  const { enterprise, setEnterprise } = useContext(UtilsContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Tooltip title="Cambiar perfil">
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={menuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
          >
            <img
              src={
                enterprise === "gmo"
                  ? logoGmo
                  : enterprise === "ray"
                  ? logoRayban
                  : enterprise === "sgh"
                  ? logoSunglass
                  : logoLafam
              }
              alt="Logo"
              style={{ padding: 10, width: "100%" }}
            />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => setEnterprise("gmo")}>
          <SwitchAccountIcon />{" "}
          <p style={{ margin: 0, padding: 0, marginLeft: 15 }}>GMO</p>
        </MenuItem>
        <MenuItem onClick={() => setEnterprise("ray")}>
          <SwitchAccountIcon />{" "}
          <p style={{ margin: 0, padding: 0, marginLeft: 15 }}>Rayban</p>
        </MenuItem>
        <MenuItem onClick={() => setEnterprise("sgh")}>
          <SwitchAccountIcon />{" "}
          <p style={{ margin: 0, padding: 0, marginLeft: 15 }}>Sunglass Hut</p>
        </MenuItem>
        <MenuItem onClick={() => setEnterprise("lfm")}>
          <SwitchAccountIcon />{" "}
          <p style={{ margin: 0, padding: 0, marginLeft: 15 }}>Lafam</p>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            signout(() => {});
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  );
}

const colors = {
  gmo: "#6c6b6a",
  ray: "white",
  sgh: "white",
  lfm: "black"
};

export default function HomeLayout({ children }) {
  const { validateLoggedAccount, isAdmin } = useAuth();
  const { signout } = useAuth();
  const { enterprise } = useContext(UtilsContext);
  const drawerPaper = useDrawerPaper({
    color:
      enterprise === "gmo"
        ? "#ECE9E8"
        : enterprise === "ray"
        ? "#FF0200"
        : enterprise === "lfm"
        ? "#8ec5e2"
        : "black",
    "&.p": "white !important",
  });
  const { pathname } = useLocation();
  const classes = useHomeLayoutStyles();
  const [open, setOpen] = React.useState(true);

  if (!validateLoggedAccount) {
    return <Redirect to={"/"} />;
  }

  if (!isAdmin) {
    return <Redirect to={"/comisiones/todas"} />;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: `${clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })} ${drawerPaper.root}`,
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon style={enterprise === "sgh" ? {color: "white"} : {}} />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon style={enterprise === "sgh" ? {color: "white"} : {}} />
            </IconButton>
          )}
        </div>
        <SwitchAccount />

        <Divider />
        <List>
          <Link to="/comisiones/todas" className={classes.homeLayoutLink}>
            <ListItem
              button
              key={"Comisiones"}
              selected={pathname === "/comisiones/todas"}
            >
              <ListItemIcon>
                <InboxIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Comisiones"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
          <Link to="/novedades/todas" className={classes.homeLayoutLink}>
            <ListItem
              button
              key={"Novedades"}
              selected={pathname === "/novedades/todas"}
            >
              <ListItemIcon>
                <FindInPageIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Novedades"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/tiendas" className={classes.homeLayoutLink}>
            <ListItem button key={"Tiendas"} selected={pathname === "/tiendas"}>
              <ListItemIcon>
                <StoreIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Tiendas"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
          <Link to="/empleados" className={classes.homeLayoutLink}>
            <ListItem
              button
              key={"Empleados"}
              selected={pathname === "/empleados"}
            >
              <ListItemIcon>
                <BadgeIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Empleados"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
          <Link to="/cuentas" className={classes.homeLayoutLink}>
            <ListItem
              button
              key={"Cuentas"}
              selected={pathname === "/cuentas"}
            >
              <ListItemIcon>
                <SupervisorAccountIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Cuentas"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/cargue" className={classes.homeLayoutLink}>
            <ListItem button key={"Cargue"} selected={pathname === "/cargue"}>
              <ListItemIcon>
                <UploadFileIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Archivos"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link to="/parametrizacion" className={classes.homeLayoutLink}>
            <ListItem
              button
              key={"Parametrización"}
              selected={pathname === "/parametrizacion"}
            >
              <ListItemIcon>
                <SettingsIcon style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Parametrización"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
            <ListItem
              button
              key={"Cerrar sesión"}
              onClick={() => {
                signout(() => {});
              }}
            >
              <ListItemIcon>
                <Logout style={{ color: colors[enterprise] }} />
              </ListItemIcon>
              <ListItemText
                primary={"Cerrar sesión"}
                style={{ color: colors[enterprise] }}
              />
            </ListItem>
        </List>
        <div style={{ height: 30 }} />
        {/* {!open ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar></Avatar>
          </Grid>
        ) : (
          <></>
        )} */}
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
}
