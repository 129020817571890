import { Grid, Paper, Typography } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const salesAdvisorColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "store_classification",
    name: "Clasificación tienda",
    type: "dropdown",
    dropdownType: "shop",
  },
  {
    field: "greater_or_lesser",
    name: "Condición",
    type: "dropdown",
    dropdownType: "greaterLesser",
  },
  {
    field: "personal_goal_percentage",
    name: "Cumplimiento",
    type: "percentage",
  },
  {
    field: "commission",
    name: "Comisión",
    type: "percentage",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
];

const salesAdvisorV2Columns = [
  {
    field: "isActual",
    name: "Activa",
    type: "bool"
  },
  {
    field: "lower_limit",
    name: "Límite inferior",
    type: "text"
  },
  {
    field: "upper_limit",
    name: "Límite superior",
    type: "text"
  },
  {
    field: "commission",
    name: "Comisión",
    type: "percentage"
  },
  {
    field: "initial_date",
    name: "Fecha de inicio",
    type: "date",
  },
  {
    field: "final_date",
    name: "Fecha de fin",
    type: "date",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  }
];

const clusterPerformanceAdvisorColumns = [
  {
    field: "isActual",
    name: "Activa",
    type: "bool"
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
  {
    field: "cluster",
    name: "Clúster",
    type: "text"
  },
  {
    field: "cluster_label",
    name: "Etiqueta de clúster",
    type: "text"
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
  {
    field: "initial_date",
    name: "Fecha de inicio",
    type: "date",
  },
  {
    field: "final_date",
    name: "Fecha de fin",
    type: "date",
  }
];

const performanceAdvisorColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "type_of_shop",
    name: "Tipo de tienda",
    type: "dropdown",
    dropdownType: "shop",
  },
  {
    field: "greater_or_lesser_store_fulfillment",
    name: "Condición cumplimiento tienda",
    type: "dropdown",
    dropdownType: "greaterLesser",
  },
  {
    field: "store_fulfillment",
    name: "Cumplimiento tienda",
    type: "percentage",
  },
  {
    field: "greater_or_lesser_mermas",
    name: "Condición cumplimiento mermas",
    type: "dropdown",
    dropdownType: "greaterLesser",
  },
  {
    field: "mermas",
    name: "Mermas",
    type: "percentage",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

const managementAdvisorInstColumns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "sales_compliance",
    name: "Cumplimiento de Ventas",
    type: "percentage",
  },
  {
    field: "portafolio",
    name: "Cartera",
    type: "percentage",
  },
  {
    field: "add_product_greater_than",
    name: "Condición Sumaproducto",
    type: "percentage",
  },
  {
    field: "bonus",
    name: "Bono",
    type: "money",
  },
];

function Asesor() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        style={{ padding: 25, margin: 0,  paddingTop: 10, backgroundColor: "#EFFCEF" }}
      >
        <RulesTable
          title="Ventas Asesor Comercial - Versión 2"
          columns={salesAdvisorV2Columns}
          uri="gmo-sales-advisor-v2"
          downloadUri="gmo-sales-advisor-v2/file"
        />
        <RulesTable
          title="Ventas Asesor Comercial"
          columns={salesAdvisorColumns}
          uri="gmo-sales-advisor"
          downloadUri="gmo-sales-advisor/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de rendimiento Clúster Asesor"
          columns={clusterPerformanceAdvisorColumns}
          uri="gmo-cluster-performance-store-advisor"
          downloadUri="gmo-cluster-performance-store-advisor/file"
        />
        <RulesTable
          title="Bonos de rendimiento"
          columns={performanceAdvisorColumns}
          uri="gmo-performance-advisor"
          downloadUri="gmo-performance-advisor/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Bonos de rendimiento Asesor Institucional"
          columns={managementAdvisorInstColumns}
          uri="gmo-management-advisor-inst"
          downloadUri="gmo-management-advisor-inst/file"
        />
      </Paper>
    </Grid>
  );
}

export default Asesor;
