import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
// import CustomComissionsTable from "../../commons/components/RulesTable/CustomComissionsTable";
// import TicketDetail from "../TicketDetail";
import { UtilsContext } from "../..";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import CustomComissionsTable from "../../commons/components/RulesTable/CustomComissionsTable";
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from "@mui/material";
import excelIcon from "../../assets/excel.png";
import CustomTicketsTable from "../../commons/components/RulesTable/CustomTicketsTable";
import InconsistencyHistoryDialog from "../CommisionDetail/InconsistencyHistoryDialog"
import { useAuth } from "../../hooks/useAuth";

const formatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
});

const trans = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

const trans_opuesto = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};

const currentDate = new Date();

function Tickets() {
  const { getToken } = useTokenManager();
  const { enterprise } = useContext(UtilsContext);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ticketId, setTicketId] = useState(-1);
  const [historyFormOpen, setHistoryFormOpen] = useState(false);

  const [year, setYear] = useState(currentDate.getFullYear());
  const [documento, setDocumento] = useState("");

  const { isAdmin } = useAuth();

  const transformedData = useMemo(
    () => {
    if( data && Array.isArray(data) ) {
      return data.map((ticket, index) => ({
        ...ticket,
        id: index,
        employee_id_document: ticket.employee.id_document,
        employee_lastname_name: ticket.employee.lastname_name,
        state: ticket.actualState === true ? "Abierto" : "Cerrado",
        finalState: ticket.finalState === true ? "Abierto" : "Cerrado",
        created_at: ticket.created_at.split("T")[0]
      }));
    }
    else {
      return [];
    }},
    [data]
  );

  const fetchExcel = () => {
    const jwt = getToken();
    fetch(
      `${FETCH_URL}${enterprise}-commission/file?&year=${year}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Authorization": "Bearer " + jwt
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Detalle de tickets ${enterprise} ${year}.xlsx`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const detailedTicket = useMemo(() => {
    if (ticketId >= 0) {
      return data[ticketId];
    }
    else return null;
  }, [data, ticketId]);

  const handleBackButton = () => {
    setTicketId(-1);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTicketId(-1);
    fetchData();
  }, [enterprise]);

  useEffect(() => {
    fetchData();
  }, [year]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    const jwt = getToken();
    fetch(
      `${FETCH_URL}tickets?year=${year}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + jwt,
        },
      }
    )
      .then((response) => response.json())
      .then((fetchedData) => {
        // console.info(fetchedData[0]);
        setData(fetchedData);
        // if (documento !== "") {
        //   const newData = fetchedData.filter(
        //     (user) => "" + user.employee.id_document === documento
        //   );
        //   console.info(newData);
        //   setData(newData);
        // } else {
        //   setData(fetchedData);
        // }

        setIsLoading(false);
      });
  }, [documento, year, getToken, enterprise]);

  return (
    <Grid>
          {
            isAdmin
            ? (
              <>
              <h1>Novedades</h1>
              <Paper variant="outlined" round style={{ padding: 15 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                  >
                    <Grid item>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Año</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={year}
                          label="Año"
                          style={{ width: 200 }}
                          onChange={(e) => {
                            setYear(e.target.value);
                          }}
                        >
                          <MenuItem value={"2021"}>2021</MenuItem>
                          <MenuItem value={"2022"}>2022</MenuItem>
                          <MenuItem value={"2023"}>2023</MenuItem>
                          <MenuItem value={"2024"}>2024</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth>
                        <TextField
                          id="outlined-basic"
                          label="Documento del empleado"
                          variant="outlined"
                          value={documento}
                          onChange={(e) => setDocumento(e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" onClick={fetchData}>
                        Filtrar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        aria-label="delete"
                        size="small"
                        onClick={fetchExcel}
                        variant="outlined"
                        style={{backgroundColor: "#EFFCEF"}}
                      >
                        <DownloadIcon fontSize="20" />
                        <img src={excelIcon} width="30" />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              </>)
            : 
            (
              <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <h1>Novedades</h1>
              </Grid>
              <Grid item>
                <FormControl fullWidth style={{marginLeft: 20}}>
                  <InputLabel id="demo-simple-select-label">Año de la comisión</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={year}
                    label="Año de la comisión"
                    style={{ width: 200 }}
                    onChange={(e) => {
                      setYear(e.target.value);
                    }}
                  >
                    <MenuItem value={"2021"}>2021</MenuItem>
                    <MenuItem value={"2022"}>2022</MenuItem>
                    <MenuItem value={"2023"}>2023</MenuItem>
                    <MenuItem value={"2024"}>2024</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
            </Grid>)
          }
          <br />
          <CustomTicketsTable
            rows={transformedData}
            onClick={(id) => {
              setTicketId(id);
              setHistoryFormOpen(true);
            }}
            isLoading={isLoading}
          />
        <InconsistencyHistoryDialog
          open={historyFormOpen}
          setOpen={setHistoryFormOpen}
          year={detailedTicket?.year}
          month={detailedTicket?.month}
          document={detailedTicket?.employee?.id_document}
        />
    </Grid>
  );
}

export default Tickets;
