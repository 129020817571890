import { Grid, Paper } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const salesAdvisorCase1Columns = [
  {
    field: "city",
    name: "Ciudad",
    type: "dropdown",
    dropdownType: "city",
  },
  {
    field: "position",
    name: "Cargo",
    type: "text",
  },
  {
    field: "type_of_optometrist",
    name: "Tipo",
    type: "text",
  },
  {
    field: "consultation_commission_with_purchase",
    name: "Consulta con compra",
    type: "money",
  },
  {
    field: "consultation_commission_without_purchase",
    name: "Consulta sin compra",
    type: "money",
  }
];

const salesAdvisorCase2Columns = [
  {
    field: "store_goal_greater_than",
    name: "Meta de tienda mayor que",
    type: "percentage",
  },
  {
    field: "store_goal_lesser_than",
    name: "Meta de tienda menor que",
    type: "percentage",
  },
  {
    field: "commission",
    name: "Comisión",
    type: "money",
  },
];

const sghChooseCaseColumns = [
  {
    field: "store_goal_lesser_than",
    name: "Meta de tienda menor que",
    type: "percentage",
  },
  {
    field: "store_goal_greater_than",
    name: "Meta de tienda mayor que",
    type: "percentage",
  },
  {
    field: "personal_goal_greater_than",
    name: "Meta personal mayor que",
    type: "percentage",
  },
  {
    field: "case1",
    name: "Caso 1",
    type: "bool",
  },
];

function Ventas() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: "100%" }}>
      <Paper
        elevation={3}
        style={{
          padding: 25,
          paddingTop: 0,
          backgroundColor: "#DEEDF6",
        }}
      >
        <div style={{ height: 10 }} />
        <RulesTable
          title="Consultas optómetra"
          columns={salesAdvisorCase1Columns}
          uri="ray-inquiries-optometrist"
          downloadUri="ray-inquiries-optometrist/file"
        />
        {/* <div style={{ height: 40 }} />
        <RulesTable
          title="Asesor de ventas (caso 2)"
          columns={salesAdvisorCase2Columns}
          uri="sgh-case2"
          downloadUri="sgh-case2/file"
        />
        <div style={{ height: 40 }} />
         <RulesTable
          title="Selección de casos"
          columns={sghChooseCaseColumns}
          uri="sgh-choose-cases"
          downloadUri="sgh-choose-cases/file"
        /> */}
      </Paper>
    </Grid>
  );
}

export default Ventas;
