import { Button, Checkbox, Grid, Hidden, Paper } from "@material-ui/core";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import avatarIcon from "../../assets/profile.png";
import portfolioIcon from "../../assets/portfolio.png";
import storeIcon from "../../assets/store.png";
import calendarIcon from "../../assets/calendar.png";
import { formatValue } from "./CommisionDetailCard";
import PieChart from "./PieChart";
import { useEffect, useState } from "react";
import useTokenManager from "../../hooks/useTokenManager";
import InconsistencyForm from "./InconsistencyForm";
import { useAuth } from "../../hooks/useAuth";
import InconsistencyHistory from "./InconsistencyHistory";
import CommisionDetailGMO from "./CommisionDetailGMO";
import CommisionDetailSGH from "./CommisionDetailSGH";
import CommisionDetailRAY from "./CommisionDetailRAY";
import CommisionDetailLFM from "./CommisionDetailLFM";

const trans = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

function CommisionDetail({ user, handleBackButton }) {
  const { isAdmin } = useAuth();
  const [historyFormOpen, setHistoryFormOpen] = useState(false);
  const [inconsistencyFormOpen, setInconsistencyFormOpen] = useState(false);

  useEffect(() => console.info(user), [user]);

  return (
    <Grid container spacing={2} style={{ backgroundColor: "#FFFFFF" }}>
      {/*<Link
        to="/comisiones/todas"
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        Regresar
      </Link>*/}
      <Button onClick={() => handleBackButton()}>Regresar</Button>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <h1>{`Comisión no. ${user.employee.id_document}_${user.month}_${user.year}`}</h1>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <Paper
          elevation={0}
          style={{
            width: "100%",
            minHeight: "150px",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "15px",
            backgroundColor: "#FAF8F4"
            
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            xs={12}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img src={avatarIcon} width="40px" alt="" />
                <h2 style={{ margin: 0, padding: 0 }}>Empleado</h2>
                <p style={{ margin: 0, padding: 0 }}>
                  {user.employee.lastname_name}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  CC {user.employee.id_document}
                </p>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img src={portfolioIcon} width="40px" alt="" />
                <h2 style={{ margin: 0, padding: 0 }}>Cargo</h2>
                <p style={{ margin: 0, padding: 0 }}>
                  {user.employee.actual_postition}
                </p>
                <p style={{ margin: 0, padding: 0, color: "#FAF8F4" }}>.</p>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{paddingTop: 10}}
              >
                <img src={storeIcon} width="40px" alt="" />
                <h2 style={{ margin: 0, padding: 0, backgroundColor: "#FAF8F4", width: "100%", textAlign: "center", zIndex: 10}}>
                  {user.employee.store_codes.length > 1 ? "Tiendas" : "Tienda"}
                </h2>
                {
                  user.employee.store_codes.length == 1
                    ? <p style={{ margin: 0, padding: 0 }} >{`${user.employee.store_codes[0].gesti_code} ${user.employee.store_codes[0].city}`}</p>
                    : (<List
                      sx={{
                        width: '100%',
                        position: 'relative',
                        overflow: 'auto',
                        top: -5,
                        maxHeight: 50,
                        '& ul': { padding: 0, margin: 0 },
                      }}
                      dense={true}
                    >
                      {user.employee.store_codes.map(((store, i) => (
                        <ListItem key={i} disablePadding>
                          <ListItemButton dense style={{margin: 0, padding: 0}}>
                            <ListItemText id={store.gesti_code} primary={`${store.gesti_code} ${store.city}`} />
                          </ListItemButton>
                        </ListItem>)))}
                    </List>)
                }
                <p style={{ margin: 0, padding: 0, color: "#FAF8F4" }}>.</p>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img src={calendarIcon} width="40px" alt="" />
                <h2 style={{ margin: 0, padding: 0 }}>Mes de la comisión</h2>
                <p style={{ margin: 0, padding: 0 }}>
                  {trans[user.month]} {" de "} {user.year}
                </p>
                <p style={{ margin: 0, padding: 0, color: "#FAF8F4" }}>.</p>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12} style={{ height: "40px", color: "white" }}>
          .
        </Grid>

        <Grid
          xs={12}
          md={9}
          container
          style={{ height: "55vh", overflowY: "auto", overflowX: "hidden" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ paddingRight: 15 }}
          >
            <Grid container>
              {user.gmo_rules_for_charge_id && <CommisionDetailGMO user={user} />}
              {user.sgh_rules_for_charge_id && <CommisionDetailSGH user={user} />}
              {user.ray_rules_for_charge_id && <CommisionDetailRAY user={user} />}
              {user.lfm_rules_for_charge_id && <CommisionDetailLFM user={user} />}
            </Grid>
          </Grid>
        </Grid>

        {/* Panel derecho, con la comisión total y el gráfico */}
        <Grid
          xs={12}
          md={3}
          container
          style={{ height: "50vh", overflow: "hidden" }}
        >
          <Paper
            elevation={0}
            style={{
              width: "100%",
              marginLeft: 15,
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "0px",
              marginTop: "-5px",
              backgroundColor: "white",
              textAlign: "center"
            }}
          >
            <h2 style={{ margin: 0, padding: 0 }}>Comisión total:</h2>
            <p style={{ margin: 0, padding: 0, fontSize: 20 }}>
              {formatValue("money", user.total_commission)}
            </p>
            <div style={{ width: "100%", height: 190, marginTop: 20 }}>
              <PieChart data={user} />
            </div>
            <div style={{ marginTop: 20 }}>
                <InconsistencyHistory
                  open={historyFormOpen}
                  setOpen={setHistoryFormOpen}
                  year={user.year}
                  month={user.month}
                  document={user.employee.id_document}
                />
                <InconsistencyForm
                    open={inconsistencyFormOpen}
                    setOpen={setInconsistencyFormOpen}
                    comissionId={`${user.employee.id_document}_${user.month}_${user.year}`}
                    month={user.month}
                    year={user.year}
                  />
            </div>
          </Paper>
        </Grid>
      </Grid>

    </Grid>
  );
}

export default CommisionDetail;
