import "./App.css";
import HomeLayout from "./commons/layouts/AdminLayout/HomeLayout";
import ComissionsWrapper from "./commons/layouts/ComissionsWrapper/ComissionsWrapper";
import CommisionDetail from "./modules/CommisionDetail/CommisionDetail";
import ParametersSwitch from "./modules/Parameters/ParametersSwitch";
import UploadFiles from "./modules/UploadFiles/UploadFiles";
import Commisions from "./modules/Commisions/Commisions";
import Login from "./modules/Login/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import StoresList from "./modules/StoresList/StoresList";
import { useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import EmployeesList from "./modules/EmployeesList/EmployeesList";
import AccountsList from "./modules/AccountsList/AccountsList";
import { useAuth } from "./hooks/useAuth";
import Tickets from "./modules/Tickets/Tickets";
import Recovery from "./modules/Login/Recovery";

function App() {
  // Constantly validates if there is an authenticated user
  const { validateLoggedAccount } = useAuth();
  useEffect(() => validateLoggedAccount(), [validateLoggedAccount]);

  const adminRoutes = useMemo(
    () => [
      {
        path: "/tiendas",
        main: () => <StoresList />,
      },
      {
        path: "/empleados",
        main: () => <EmployeesList />,
      },
      {
        path: "/cuentas",
        main: () => <AccountsList />,
      },
      {
        path: "/cargue",
        main: () => <UploadFiles />,
      },
      {
        path: "/parametrizacion",
        main: () => <ParametersSwitch />,
      },
    ],
    []
  );

  return (
    <Router>
      <div style={{ display: "flex" }}>
        <Switch>
          <Route
            key={"home"}
            path={"/"}
            exact={"/"}
            children={<Redirect to={"/comisiones/todas"} />}
          />
          <Route
            key={"login"}
            path={"/login"}
            exact={"/login"}
            children={<Login />}
          />
          <Route
            key={"recover"}
            path={"/recuperar"}
            // exact={"/recuperar"}
            children={<Recovery />}
          />

          <Route
            key={"/comisiones/todas"}
            path={"/comisiones/todas"}
            exact={"/comisiones/todas"}
            children={
              <ComissionsWrapper>
                <Commisions />
              </ComissionsWrapper>
            }
          />

          <Route
            key={"/novedades/todas"}
            path={"/novedades/todas"}
            exact={"/novedades/todas"}
            children={
              <ComissionsWrapper>
                <Tickets />
              </ComissionsWrapper>
            }
          />

          <Route
            key={"/comisiones/detalle"}
            path={"/comisiones/detalle"}
            exact={"/comisiones/detalle"}
            children={
              <ComissionsWrapper>
                <CommisionDetail />
              </ComissionsWrapper>
            }
          />

          <Route
            key={"/novedades/detalle"}
            path={"/novedades/detalle"}
            exact={"/novedades/detalle"}
            children={
              <ComissionsWrapper>
                <Commisions />
              </ComissionsWrapper>
            }
          />

          <HomeLayout>
            <div style={{ flex: 1, padding: "10px" }}>
              {adminRoutes.map((route, index) => (
                // Render more <Route>s with the same paths as
                // above, but different components this time.
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={<route.main />}
                />
              ))}
            </div>
          </HomeLayout>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
