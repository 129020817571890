import { makeStyles } from "@material-ui/core/styles";

const useCustomDialogStyles = makeStyles((theme) => ({
  customDialog: {
    borderRadius: "10px",
    minWidth: "650px",

  },
  customDialogContent: {
    backgroundColor: "white",
    padding: "20px",
    width: "100%",
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      padding: "20px 40px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 40px 20px 40px",
    },
  },
  customDialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.primary.main,
    padding: "10px 20px",
    // borderBottom   : `1px solid ${ theme.palette.gray.light }`,

    [theme.breakpoints.up("sm")]: {
      padding: "20px 40px",
    },
  },
  customDialogTitleOnlyCloseButton: {
    justifyContent: "flex-end",
  },
  customDialogFooter: {
    padding: "10px 20px",
    justifyContent: "space-around",
    " & button": {
      margin: "0 !important",
    },

    [theme.breakpoints.up("sm")]: {
      padding: "20px 40px",
    },
  },
}));
export default useCustomDialogStyles;
