import { Grid } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";
import { Paper } from "@mui/material";

function ReglasPorCargo() {
  // const columns = [
  //   {
  //     field: "charge",
  //     headerName: "Cargo",
  //     onClick: () => {
  //       setOpenCityDialog(true);
  //     },
  //   },
  //   {
  //     field: "gmo_Inquiries_optometrist",
  //     headerName: "Consultas optómetra",
  //     onClick: () => {
  //       setOpenGestionDialog(true);
  //     },
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  //   {
  //     field: "gmo_management_advisor_inst",
  //     headerName: "Asesor de gestión",
  //     description: "Puede seleccionar entre mayor que, menor que e igual.",
  //     onClick: () => setOpenBossDialog(true),
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  //   {
  //     field: "gmo_management_boss",
  //     headerName: "Jefe de tienda",
  //     description: "El valor expresado en porcentaje.",
  //     onClick: () => setOpenGestionDialog(true),
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  //   {
  //     field: "gmo_management_zboss",
  //     headerName: "Jefe zonal",
  //     description: "El valor expresado en porcentaje.",
  //     onClick: () => setOpenGestionDialog(true),
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  //   {
  //     field: "gmo_performance_advisor",
  //     headerName: "Rendimiento de asesor",
  //     description: "El valor expresado en porcentaje.",
  //     onClick: () => setOpenGestionDialog(true),
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  //   {
  //     field: "gmo_performance_optometrist",
  //     headerName: "Rendimiento de optómetra",
  //     description: "El valor expresado en porcentaje.",
  //     width: "300",
  //     onClick: () => setOpenGestionDialog(true),
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  //   {
  //     field: "gmo_sales_advisor",
  //     headerName: "Asesor de ventas",
  //     description: "El valor expresado en porcentaje.",
  //     onClick: () => setOpenGestionDialog(true),
  //     valueParser: (value) => (value ? "Activada" : "Desactivada"),
  //   },
  // ];

  const columns = [
    {
      field: "charge",
      name: "Cargo",
      type: "text",
    },
    {
      field: "gmo_sales_advisor",
      name: "Asesor de ventas",
      type: "bool",
    },
    {
      field: "gmo_performance_advisor",
      name: "Rendimiento de asesor",
      type: "bool",
    },
    {
      field: "gmo_management_advisor_inst",
      name: "Asesor de gestión",
      type: "bool",
    },
    {
      field: "gmo_management_boss",
      name: "Jefe de tienda",
      type: "bool",
    },
    {
      field: "gmo_inquiries_boptometric",
      name: "Consultas de jefe optómetra",
      type: "bool",
    },
    {
      field: "gmo_management_zboss",
      name: "Jefe zonal",
      type: "bool",
    },
    {
      field: "gmo_inquiries_optometrist",
      name: "Consultas optómetra",
      type: "bool",
    },
    {
      field: "gmo_performance_optometrist",
      name: "Rendimiento de optómetra",
      type: "bool",
    },
    {
      field: "gmo_performance_optometrist2022",
      name: "Rendimiento de optómetra 2022",
      type: "bool",
    },
    {
      field: "gmo_inquiries_optometrist2022",
      name: "Consultas optómetra 2022",
      type: "bool",
    },
    {
      field: "gmo_inquiries_bonus_optometrist2022",
      name: "Consultas bono optómetra 2022",
      type: "bool",
    },
    {
      field: "gmo_management_boss_optometrist2022",
      name: "Consultas jefe optómetra 2022",
      type: "bool",
    },
  ];


  const gmoRangesColumns = [
    {
      field: "variable_name",
      name: "Nombre de la variable",
      type: "text",
    },
    {
      field: "label",
      name: "Etiqueta",
      type: "text",
    },
    {
      field: "greater_than",
      name: "Mayor que",
      type: "percentage",
    },
    {
      field: "lesser_than",
      name: "Menor que",
      type: "percentage",
    },
    {
      field: "result",
      name: "Resultado",
      type: "percentage",
    },
    {
      field: "with_goal",
      name: "Con meta",
      type: "bool",
    },
  ];

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        style={{ padding: 25, margin: 0, paddingTop: 10, backgroundColor: "#DEEDF6" }}
      >
        <RulesTable
          title="Reglas por cargo"
          columns={columns}
          uri="gmo-rules-for-charge"
          downloadUri="gmo-rules-for-charge/file"
        />
        <div style={{ height: 40 }} />
        <RulesTable
          title="Rangos"
          columns={gmoRangesColumns}
          uri="gmo-ranges"
          downloadUri="gmo-ranges/file"
        />
      </Paper>
    </Grid>
  );
}

export default ReglasPorCargo;
