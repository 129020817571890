import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTable from "../../commons/components/CustomTable/CustomTable";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";

function StoresList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { getToken } = useTokenManager();

  const fetchData = () => {
    setLoading(true);
    const jwt = getToken();
    fetch(`${FETCH_URL}store`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + jwt,
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((fetchedData) => {
        setData(fetchedData);
        setLoading(false);
      });
  };

  useEffect(() => fetchData(), []);

  const columns = [
    { id: "gesti_code", label: "GESTI", desiredWidth: 70 },
    { id: "sap_code", label: "SAP", desiredWidth: 70 },
    { id: "city", label: "Ciudad", desiredWidth: 150 },
    { id: "name", label: "Nombre", desiredWidth: 300 },
    { id: "zone", label: "Zona", desiredWidth: 150 },
    { id: "type_optometrist", label: "Tipo optómetra", desiredWidth: 100 },
    {
      id: "type_advisor_bogota",
      label: "Tipo de asesor Bogotá",
      desiredWidth: 100,
    },
  ];

  return (
    <Grid>
      <h1>Tiendas</h1>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <CustomTable
          columns={columns}
          data={data}
          error={error}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}

export default StoresList;
