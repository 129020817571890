/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useCallback, useMemo, useState } from "react";
//import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { Grid, IconButton, LinearProgress, Tooltip } from "@material-ui/core";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  progressBarContainer: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function CustomTicketsTable({ rows, onClick, isLoading }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [pagination, setPagination] = useState([0, 10]);
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        field: "month",
        headerName: "Mes"
      },
      {
        field: "year",
        headerName: "Año"
      },
      {
        field: "bossMail",
        headerName: "Jefe"
      },
      {
        field: "state",
        headerName: "Estado"
      },
      {
        field: "created_at",
        headerName: "Creado el"
      },
      {
        field: "employee_id_document",
        headerName: "Documento empleado"
      },
      {
        field: "employee_lastname_name",
        headerName: "Nombre empleado"
      },
      {
        field: "detail",
        headerName: "Detalle",
        onClick: onClick,
      },
    ],
    [onClick]
  );

  const handleChangePage = (event, newPage) => {
    setPagination([newPage * 10, (newPage + 1) * 10]);
    setCurrentPage(newPage);
  };

  const createRow = useCallback((row) => {
    return (
      <TableRow hover={true} role="checkbox" tabIndex={-1} key={row.code}>
        {columns.map((column) => {
          const value = row[column.field];

          return (
            <TableCell>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {column.onClick ? (
                  <Tooltip title="Ver detalle">
                    <IconButton onClick={() => onClick(row.id)}>
                      <ZoomInIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <p>
                    {column.valueParser ? column.valueParser(value) : value}
                  </p>
                )}
              </Grid>
            </TableCell>
          );
        })}
      </TableRow>
    );
  }, []);

  return (
    <Paper className={classes.root}>
      <div style={{ height: "5px" }}>
        {isLoading ? <LinearProgress /> : <></>}
      </div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <colgroup>
            {columns.map((column, i) => (
              <col key={"colWidth_" + i} width={400} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? (
              rows.length ? (
                rows
                  .slice(pagination[0], pagination[1])
                  .map((row) => createRow(row))
              ) : (
                <TableRow hover={true} role="checkbox" tabIndex={-1}>
                  <TableCell colSpan={5}>
                    <p>No se encontraron resultados</p>
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow hover={true} role="checkbox" tabIndex={-1}>
                <TableCell colSpan={5}>
                  <p>Cargando...</p>
                </TableCell>
              </TableRow>
            )}
            {/*error && (
              <TableRow hover={true} role="checkbox" tabIndex={-1}>
                <TableCell colSpan={5}>
                  <p>{error}</p>
                </TableCell>
              </TableRow>
            )*/}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component={"div"}
        count={rows?.length}
        rowsPerPage={10}
        labelRowsPerPage={"Registros por página"}
        backIconButtonText={"Anterior"}
        nextIconButtonText={"Siguiente"}
        page={currentPage}
        onChangePage={handleChangePage}
        labelDisplayedRows={({ from, to, count, page }) =>
          `Página ${page + 1
          }: mostrando de ${from} a ${to} registros de un total de ${count !== -1 ? count : 0
          }`
        }
      />
    </Paper>
  );
}

/*UsersInfo.propTypes = {
  token: PropTypes.string,
  users: PropTypes.arrayOf(UserInfoPropTypes),
  isLoading: PropTypes.bool,
  setFilteredUser: PropTypes.fun,
  currentPage: PropTypes.number,
  size: PropTypes.number,
  numberOfRegisters: PropTypes.number,
  setQueryParams: PropTypes.fun,
};*/

export default CustomTicketsTable;
