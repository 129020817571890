import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import BossesList from './BossesList';
import { Typography } from '@material-ui/core';
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";


export default function InconsistencyForm({ open, setOpen, month, year, comissionId }) {
  const { getToken } = useTokenManager();
  const [bosses, setBosses] = useState([]);
  const [bossEmail, setBossEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (open) {
      const jwt = getToken();

      fetch(
        `${FETCH_URL}employees/bosses`,
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + jwt,
          },
        }
      ).then((response) => response.json())
        .then((fetchedData) => {
          setBosses(fetchedData);
        });
    }
  }, [open]);

  const createInconsistency = () => {
    const jwt = getToken();

    fetch(
      `${FETCH_URL}tickets`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + jwt,
        },
        body: JSON.stringify({
          "bossMail": bossEmail,
          "initialDescription": description,
          "month": month,
          "year": year
        })
      }
    ).then((response) => {
      // console.info(response.json());
      const code = String(response.status);

      if (code.startsWith("2")) {
        setMessage("La inconsistencia se ha creado exitosamente, revise su correo electrónico.")
      }
      else {
        setMessage("Ha ocurrido un error reportando la inconsistencia, por favor comuníquese con el administrador.")
      }

      setIsLoading(false);
    })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setMessage(null);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Reportar inconsistencia
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{`Formulario de inconsistencias - Comisión ${comissionId}`}</DialogTitle>
        <DialogContent style={{ padding: 50, paddingTop: 10 }}>
          <div style={{ backgroundColor: "#FAF8F3", borderRadius: "10px", padding: 15 }}>
            <Typography variant="h7">
              Jefe a notificar:
            </Typography>
            {bosses.length ? (<BossesList bosses={bosses} email={bossEmail} setEmail={setBossEmail} />) : (<p>Cargando...</p>)}
          </div>
          <div style={{ height: "30px" }}></div>
          {
            message ? (<p>{message}</p>)
            : <>
            <Typography variant="h7">
              Descripción
            </Typography>
            <TextField
              autoFocus
              id="name"
              type="text"
              fullWidth
              variant="standard"
              placeholder='Por favor describa brevemente la inconsistencia'
              multiline={true}
              minRows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            </>
          }
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={createInconsistency} disabled={isLoading || message}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
