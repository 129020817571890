import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import UploadArea2 from "./upload-file-component";
import { trans_opuesto } from "./UploadFiles";


const UploadUserAccounts = ({ year, month, setError, setLoading, setSuccess, loading, setLogs }) => {
    const [files, setFiles] = useState({
        monthly_accounts: undefined
    });

    const { getToken } = useTokenManager();

    const handleFileChange = (identifier, value) => {
        setFiles((prevState) => ({ ...prevState, [identifier]: value }));
    };

    // Esta funcion hace toda la lógica para dar respuesta al requerimiento de subir los archivos
    const handleSubmit = async () => {
        setError("");
        setLoading(true);

        // Validaciones de la logica del negocio
        if (!files.monthly_accounts) {
            setError("El archivo de cuentas de usuarios es obligatorio.");
            setLoading(false);
            return;
        }
        if (!files.monthly_accounts.name.endsWith("xlsx")) {
            setError("Formato de cuentas de usuarios inválido.");
            setLoading(false);
            return;
        }

        try {
            // Promesas a ejecutar para enviar los archivos
            const jwt = getToken();

            // Obligatorias:
            const monthlyUserAccountData = new FormData();
            monthlyUserAccountData.append("file", files.monthly_accounts);

            setLogs("Cargando...")
            fetch(
                `${FETCH_URL}users/upload?year=${year}&month=${trans_opuesto[month]}`,
                {
                    body: monthlyUserAccountData,
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                        "Authorization": "Bearer " + jwt
                    }
                }
            ).then(response => response.text())
                .then(raw => {
                    setLogs(raw);
                    setLoading(false);
                }).catch(() => {
                    setLogs("");
                    setLoading(false);
                });
        } catch (err) {
            setError(JSON.stringify(err, Object.getOwnPropertyNames(err)));
            setLoading(false);
            setSuccess("");
        }
    };

    return (
        <Grid container direction="column">
            <Grid container spacing={2} style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                    <h3 style={{ margin: 0, padding: 0 }}>
                        Datos mensuales de cuentas de usuarios *
                    </h3>
                    <UploadArea2
                        hint="archivo de datos mensuales de cuentas de usuarios"
                        onUpload={handleFileChange}
                        identifier={"monthly_accounts"}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                {loading ? (
                    <p>Cargando...</p>
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                    >
                        Enviar
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

export default UploadUserAccounts;
