import { useCallback } from "react";
import { useAuth } from "./useAuth";

export const parseFullJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const _parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};


export const _isTokenValid = (jwtToCheck) => {
  const isValid = !!jwtToCheck &&
  Boolean(_parseJwt(jwtToCheck).exp * 1000 > new Date().getTime());
  return isValid;
}


export function useTokenManager() {
  const { jwt, signout } = useAuth();

  const getToken = useCallback(() => {
    if (!jwt) return undefined;
    else if (_isTokenValid(jwt)) return jwt;
    else signout();
  }, [jwt]);

  return {
    getToken,
  };
}

export default useTokenManager;
