import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    height: "500px",
  },
  progressBarContainer: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function CustomTable({ columns, data, loading, error }) {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
  };

  const createRow = useCallback((row) => {
    return (
      <TableRow hover={true} role="checkbox" tabIndex={-1} key={row.code}>
        {columns.map((column) => {
          const value = row[column.id];

          return (
            <TableCell key={column.id}>
              {
                column.componentGetter ? column.componentGetter(row)
                : column.format ? column.format(value) : value ?? "-"
              }
            </TableCell>
          );
        })}
      </TableRow>
    );
  }, []);

  return (
    <Paper className={classes.root}>
      <div style={{ height: "5px" }}>
        {loading ? <LinearProgress /> : <></>}
      </div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" style={{ width: "100%" }}>
          <colgroup>
            {columns.map((column, i) => (
              <col
                key={"colWidth_" + i}
                width={column.width ? column.width : 400}
              />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableRow hover={true} role="checkbox" tabIndex={-1}>
                <TableCell colSpan={5}>
                  <p>{error}</p>
                </TableCell>
              </TableRow>
            ) : !data.length && !loading ? (
              <TableRow hover={true} role="checkbox" tabIndex={-1}>
                <TableCell colSpan={5}>
                  <p>No se encontraron resultados</p>
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
            {/* Build body rows */}
            {data
              .slice(page * size, page * size + size)
              .map((row) => createRow(row))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 15, 20, 50]}
        component={"div"}
        count={data ? data.length : 0}
        rowsPerPage={size}
        labelRowsPerPage={"Usuarios por página"}
        backIconButtonText={"Anterior"}
        nextIconButtonText={"Siguiente"}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count, page }) =>
          `Página ${page + 1}: ${from} a ${to} de ${
            count !== -1 ? count : 0
          } registros`
        }
      />
    </Paper>
  );
}

export default CustomTable;
