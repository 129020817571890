import { Grid, TextField } from "@material-ui/core";
import { Input, Button, Alert } from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/luxottica.jpg";
import gmo from "../../assets/gmo_v_col_rgb.png";
import sgh from "../../assets/sunglass-hut.png";
import ray from "../../assets/rayban_login.png";
import { FETCH_URL } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from 'react-router-dom';

function Recovery() {
  const auth = useAuth();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [assignPasswordView, setAssignPasswordView] = useState(false);
  const location = useLocation();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  const email = params.email;
  const token = params.token; // "some_value"


  const handleRecoverPassword = () => {
    setError("");

    if( password !== newPassword) {
      setError("Las contraseñas no coinciden.");
      return;
    }

    fetch(`${FETCH_URL}users/resetpassword/?token=${token}&email=${email}`, {
      body: JSON.stringify({
        newPassword: newPassword
      }),
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      cache: "no-cache",
      credentials: "same-origin",
    })
    .then((response) => {
      if (response.status === 201) {
        setSuccess(
          "Contraseña recuperada exitosamente. Por favor, dirígete a iniciar sesión."
        );
      }
    })
    .catch((err) => {
      setError(JSON.stringify(err, Object.getOwnPropertyNames(err)));
      setSuccess("");
      setLoading(false);
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#fff" }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "rgb(0,55,101)",
        }}
      >
        <img src={logo} alt="" height={150} />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#fff",
          minHeight: "20vh",
          width: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={5}
          alignItems="center"
        >
          <Grid item>
            <img src={sgh} alt="" width={200} />
          </Grid>
          <Grid item>
            <img src={gmo} alt="" width={90} />
          </Grid>
          <Grid item>
            <img src={ray} alt="" width={200} />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#fff",
          minHeight: "40vh",
          width: "98vw",
        }}
      >
        <h1 style={{ color: "rgb(0, 55, 101)" }}>Recuperar contraseña</h1>
        <Grid container direction="column" xs={12} sm={4}>
          <TextField
            id="email"
            label="Correo electrónico *"
            variant="outlined"
            value={email}
            style={{ backgroundColor: "white", width: "100%", marginBottom: 20 }}
            disabled
            // onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="password"
            label="Nueva contraseña *"
            variant="outlined"
            type={"password"}
            style={{ backgroundColor: "white", width: "100%", marginBottom: 20 }}
            onChange={(e) => setPassword(e.target.value)}
          />
            <TextField
              id="newPassword"
              label="Confirmar contraseña *"
              variant="outlined"
              type={"password"}
              style={{ backgroundColor: "white", width: "100%", marginBottom: 20 }}
              onChange={(e) => setNewPassword(e.target.value)}
            />
        </Grid>
        {error ? (
          <Alert severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ) : (
          <></>
        )}
        {success ? (
          <Alert severity="success" style={{ marginBottom: 20 }}>
            {success}
          </Alert>
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          style={{ backgroundColor: "rgb(0, 55, 101)" }}
          onClick={handleRecoverPassword}
        >
          Asignar nueva contraseña
        </Button>
      </Grid>
    </Grid>
  );
}

export default Recovery;
