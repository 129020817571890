import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';


export default function BossesList({ bosses, email, setEmail }) {
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailChange = (email) => {
    setEmail(email);
  };

  return (<FormControl>
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={email}
      onChange={handleChange}
    >
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 180,
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
        dense={true}
      >
        {bosses.map((boss, i) => (
          <ListItem key={boss.employess_id_document} disablePadding disabled={!boss.users_email} onClick={() => handleEmailChange(boss.users_email)}>
            <ListItemButton role={undefined} dense>
              <ListItemIcon>
                <FormControlLabel disabled={!boss.users_email} value={boss.users_email} control={<Radio />} label={""} />
              </ListItemIcon>
              <ListItemText
                id={`checkbox-list-label-${boss.employess_id_document}`}
                primary={boss.lastname_name} secondary={boss.users_email}
                disabled={!boss.users_email}
              />
            </ListItemButton>
          </ListItem>)
        )}
      </List>
    </RadioGroup>
  </FormControl>
  );

  // return (
  //   <List
  //     sx={{
  //       width: '100%',
  //       position: 'relative',
  //       overflow: 'auto',
  //       maxHeight: 180,
  //       '& ul': { padding: 0 },
  //     }}
  //     subheader={<li />}
  //     dense={true}
  //   >
  //     <FormControl>
  //       {/* <FormLabel id="demo-controlled-radio-buttons-group">Texto de prueba!</FormLabel> */}
  //       <RadioGroup
  //         aria-labelledby="demo-controlled-radio-buttons-group"
  //         name="controlled-radio-buttons-group"
  //         value={value}
  //         onChange={handleChange}
  //       >

  //         {bosses.map((boss, i) => {
  //           const labelId = ;
  //           return (
  //             <ListItem key={boss.employess_id_document} disablePadding>
  //               <ListItemButton role={undefined} dense>
  //                 <ListItemIcon>
  //                   <FormControlLabel value={boss.employees_id_document} control={<Radio />} label={i} />
  //                 </ListItemIcon>
  //                 {/* <ListItemText id={labelId} primary={boss.lastname_name} secondary={boss.users_email} onClick={() => setValue(boss.employees_id_document)} /> */}
  //               </ListItemButton>
  //             </ListItem>
  //           );
  //         })}
  //       </RadioGroup>
  //     </FormControl>

  //   </List>
  // );
}
