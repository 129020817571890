import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTable from "../../commons/components/CustomTable/CustomTable";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import NewAccountForm from "./NewAccountForm";
import CustomDialog from "../../commons/components/CustomDialog/CustomDialog"
import { FormControlLabel, FormGroup, Switch } from "@mui/material";


function CustomSwitch({ checked, onClick }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={onClick}
          />
        }
        label={checked ? "Activo" : "Inactivo"} />
    </FormGroup>
  );
}


function AccountsList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { getToken } = useTokenManager();

  const changeIsActive = (email, currentIsActive) => {
    const jwt = getToken();
    fetch(`${FETCH_URL}users/${currentIsActive ? "deactive" : "active"}Users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + jwt
      },
      body: JSON.stringify({
        "emails": [
          email
        ]
      }),
    }).then(() => {
      // console.info("sent!")
      fetchData();
    }).catch((error) => console.error(error))
  }

  const changeNotifyMe = (email, currentIsActive) => {
    const jwt = getToken();
    fetch(`${FETCH_URL}users/notifyme`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + jwt
      },
      body: JSON.stringify({
        "users": [{
          "email": email,
          "notifyMe": !currentIsActive
        }
        ]
      }),
    }).then(() => {
      // console.info("sent!")
      fetchData();
    }).catch((error) => console.error(error))
  }


  const fetchData = () => {
    setLoading(true);
    const jwt = getToken();
    fetch(`${FETCH_URL}users`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization:
          "Bearer " + jwt,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((fetchedData) => {
        setData(fetchedData.sort((account1, account2) => {
          if (account1.rol === "user") return 1;
          else return -1;
        }));
        setLoading(false);
      });
  };

  useEffect(() => fetchData(), []);

  const columns = [
    { id: "documentId", label: "Documento", desiredWidth: 70 },
    { id: "email", label: "Correo", desiredWidth: 300 },
    { id: "name", label: "Nombre", desiredWidth: 150 },
    { id: "lastname", label: "Apellido", desiredWidth: 150 },
    { id: "rol", label: "Rol", desiredWidth: 150, format: (val) => val === "admin" ? "Administrador" : "Empleado" },
    {
      id: "isActive",
      label: "¿Habilitado?",
      desiredWidth: 150,
      componentGetter: (user) => (
        <CustomSwitch
          onClick={() => changeIsActive(user.email, user.isActive)}
          checked={user.isActive}
        />
      )
    },
    {
      id: "notifyMe",
      label: "¿Suscrito a notificaciones?",
      desiredWidth: 150,
      componentGetter: (user) => {
        if (user.rol === "admin") {
          return (<CustomSwitch
            onClick={() => changeNotifyMe(user.email, user.notifyMe)}
            checked={user.notifyMe}
          />);
        }
        else {
          return <p>No aplica</p>;
        }
      }
    }
  ];

  return (
    <Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <h1>Cuentas de usuarios</h1>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  // setAdd(true);
                  setOpenDialog(true);
                }}
              >
                Añadir administrador
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CustomTable
        columns={columns}
        data={data}
        error={error}
        loading={loading}
      />

      <CustomDialog
        title={"Añadir un nuevo administrador"}
        open={openDialog}
        handler={() => {
          setOpenDialog(false);
        }}
      >
        <NewAccountForm
          close={() => setOpenDialog(false)}
          refresh={() => fetchData()}
        />
      </CustomDialog>
    </Grid>
  );
}

export default AccountsList;
