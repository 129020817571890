import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import excelIcon from "../../assets/excel.png";

const customDropzoneStyles = {
  textAlign: "center",
  padding: "30px",
  border: "3px dashed #eeeeee",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  cursor: "pointer",
  marginBottom: "20px",
  borderRadius: 6
};

export default function UploadFiles({ hint, onUpload, identifier }) {
  const [state, setState] = useState({
    selectedFiles: undefined,
    currentFile: undefined,
    progress: 0,
    message: "",
    fileInfos: [],
  });

  const onDrop = (files) => {
    if (files.length > 0) {
      setState({ selectedFiles: files });
    }
  };

  useEffect(() => {
    if (state.selectedFiles && state.selectedFiles[0].name) {
      onUpload(identifier, state.selectedFiles[0]);
    }
  }, [identifier, onUpload, state.selectedFiles]);

  return (
    <Dropzone onDrop={onDrop} multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <h1>{state.hint}</h1>
          <div
            {...getRootProps({ className: "dropzone" })}
            style={customDropzoneStyles}
          >
            <div style={{ minHeight: "90px", width: "100%" }}>
              <input {...getInputProps()} />
              {state.selectedFiles && state.selectedFiles[0].name ? (
                <div className="selected-file">
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{height: 90}}
                  >
                    <img src={excelIcon} alt="" height={40} />
                    <p>{state.selectedFiles && state.selectedFiles[0].name}</p>
                  </Grid>
                </div>
              ) : (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    height: "90px"
                  }}
                >
                  <p>{`Arrastre el ${hint} aquí, o haga clic para seleccionar uno desde su computador.`}</p>
                </Grid>
              )}
            </div>
          </div>
          <aside className="selected-file-wrapper"></aside>
        </section>
      )}
    </Dropzone>
  );
}
