import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DescriptionIcon from "@mui/icons-material/Description";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const dummieList = [
  { title: "Plantilla de empleados Luxottica", uploadedAt: "28/10/2021" },
  {
    title: "Plantilla datos mensuales tienda Luxottica",
    uploadedAt: "28/10/2021",
  },
  {
    title: "Plantilla datos mensuales Empleados Luxottica Vacio",
    uploadedAt: "28/10/2021",
  },
  { title: "Plantilla de empleados Luxottica", uploadedAt: "28/10/2021" },
  {
    title: "Plantilla datos mensuales tienda Luxottica",
    uploadedAt: "28/10/2021",
  },
  {
    title: "Plantilla datos mensuales Empleados Luxottica Vacio",
    uploadedAt: "28/10/2021",
  },
  { title: "Plantilla de empleados Luxottica", uploadedAt: "28/10/2021" },
];


export default function FilesList() {
  const [files, setFiles] = useState();
  const { getToken } = useTokenManager();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const jwt = getToken();
    fetch(`${FETCH_URL}files`, { headers: { 'Authorization': 'Bearer ' + jwt } })
      .then((response) => response.json())
      .then((d) => {
        setFiles(d);
      })
      .catch((e) => console.error(e));
  }, []);

  const downloadFile = (folder, fileName) => {
    const jwt = getToken();
    fetch(
      `${FETCH_URL}files/file?path=${folder}/${fileName}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Authorization": "Bearer " + jwt
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          fileName
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };


  return (
    <Grid container spacing={2}>
      <Grid item style={{ height: "300px", overflowY: "auto", width: "100%" }}>
        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div" style={{ paddingTop: "0px", marginTop: "0px" }}>
          Archivos en el sistema:
        </Typography>

        {files ? Object.keys(files).map((folderName) => (
          <Accordion expanded={expanded === folderName} onChange={handleChange(folderName)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{ margin: 0, padding: 0, height: 60 }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={folderName}
                />
              </ListItem>
            </AccordionSummary>
            <AccordionDetails style={{ margin: 0, padding: 0 }}>
              <List dense={true} style={{ width: "100%" }}>
                {files[folderName].subFiles.map((file) => (
                  <ListItem
                    style={{ paddingLeft: 40 }}
                    secondaryAction={
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                      >
                        <Grid item xs style={{ paddingRight: 40 }}>
                          <IconButton
                            edge="end"
                            aria-label="download"
                            onClick={() => downloadFile(folderName, file)}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <DescriptionIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={file}
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))
          : <p>Cargando...</p>
        }

      </Grid>
    </Grid>
  );
}
