import { Grid } from "@material-ui/core";
import {
  AppBar,
  Button,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { UtilsContext } from "../../..";
import { useAuth } from "../../../hooks/useAuth";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";


const NavBar = () => {
  const { enterprise, setEnterprise } = useContext(UtilsContext);
  const { email, signout } = useAuth();
  const { pathname } = useLocation();

  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ boralignItemsderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} style={{ paddingLeft: 25 }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Link to="/comisiones/todas" style={{ textDecoration: "none", color: "black" }}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, fontWeight: pathname === "/comisiones/todas" ? "bolder" : "1" }}
                  >
                    Comisiones
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={4}>
                <Link to="/novedades/todas" style={{ textDecoration: "none", color: "black" }}>
                  <Typography
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, fontWeight: pathname === "/novedades/todas" ? "bolder" : "1" }}
                  >
                    Novedades
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={enterprise}
                  label="Empresa"
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                  onChange={(val) => setEnterprise(val.target.value)}
                >
                <MenuItem value={"gmo"}>GMO</MenuItem>
                <MenuItem value={"ray"}>RayBan</MenuItem>
                <MenuItem value={"sgh"}>Sunglass Hut</MenuItem>
                <MenuItem value={"lfm"}>Lafam</MenuItem>
              </Select>

              {email ? <p>{email}</p> : <></>}
              {email ? (
                <>
                  {" "}
                  <Button
                    // variant="outlined"
                    sx={{ my: 1, mx: 1.5 }}
                    onClick={() => signout(() => { })}
                  >
                    Cerrar sesión
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};

export default NavBar;
