import { Grid } from "@material-ui/core";

const moneyFormatter = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
});

export const formatValue = (type, value) => {
  switch (type) {
    case "percentage":
      return value
        ? (parseFloat(value) * 100).toFixed(2) + "%"
        : "N/A" /* "0,00%"*/;
    case "money":
      return value === 0
        ? "$ 0,00"
        : value
        ? moneyFormatter.format(value)
        : "N/A" /*"$ 0,00"*/;
    default:
      return value;
  }
};

const CommisionDetailCard = ({ title, color, value, valueType }) => {
  return (
    <Grid
      style={{
        height: "133px",
        width: "100%",
        padding: 10,
        backgroundColor: color,
        borderRadius: 15,
      }}
    >
      <Grid item style={{ minHeight: 86 }}>
        <p
          style={{
            margin: 0,
            padding: 0,
            fontWeight: 400,
            fontSize: 20,
          }}
        >
          {title}
        </p>
      </Grid>
      <Grid item>
        <p
          style={{
            margin: 0,
            padding: 0,
            textAlign: "right",
            fontSize: 22,
            fontWeight: 500,
            top: 10,
            right: -5,
          }}
        >
          {formatValue(valueType, value)}
        </p>
      </Grid>
    </Grid>
  );
};

export default CommisionDetailCard;
