import { makeStyles } from "@material-ui/core";

export const useGmoRaybanParametersStyles = makeStyles(() => ({
  tabContainer: {
    background: "#fff",
    float: "left",
    border: "3px solid #7BAFD4",
    padding: "10px",
  },
  selectedTab: {
    minWidth: 50,
    textAlign: "center",
    cursor: "pointer",
    marginRight: 10,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    color: "white",
    fontWeight: "bolder",
    backgroundColor: "#7BAFD4",
    zIndex: 3,
  },
  nonSelectedTab: {
    minWidth: 50,
    textAlign: "center",
    cursor: "pointer",
    marginRight: 10,
    padding: 5,
    paddingLeft: 15,
    paddingRight: 15,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    color: "white",
    backgroundColor: "#978C87",
    zIndex: 0,
  },
}));
