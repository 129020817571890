import { Grid, TextField } from "@material-ui/core";
import { Button, Alert, InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/luxottica.jpg";
import gmo from "../../assets/gmo_v_col_rgb.png";
import sgh from "../../assets/sunglass-hut.png";
import ray from "../../assets/rayban_login.png";
import lfm from "../../assets/Lafam 1.png";
import { FETCH_URL } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { Link, Redirect } from "react-router-dom";
import useTokenManager from "../../hooks/useTokenManager";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function Login() {
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [assignPasswordView, setAssignPasswordView] = useState(false);
  const [isRecoveringPassword, setIsRecoveringPassword] = useState(false);
  const { getToken } = useTokenManager();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (auth.validateLoggedAccount()) {
    // return <Redirect to={"/parametrizacion"} />;
    return <Redirect to={"/comisiones/todas"} />;
  }

  const handleLogin = () => {
    setError("");
    setLoading(true);
    auth.signin(
      { email, password },
      () => {
        setSuccess("Inició sesión correctamente");
        setLoading(false);
      },
      (errorMsg) => {
        setError(errorMsg);
        setLoading(false);
      },
      setAssignPasswordView
    );
  };

  const handleSendRecoverEmail = () => {
    setError("");
    const jwt = getToken();
    fetch(`${FETCH_URL}users/forgotPassword`, {
      body: JSON.stringify({email}),
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      cache: "no-cache",
      credentials: "same-origin",
    })
      .then((response) => {
        setSuccess(
          `Si ${email} coincide con un correo registrado en COMISUS, se envió un correo electrónico con instrucciones para la recuperación.`
        );
        setLoading(false);
      })
      .catch((err) => {
        setError(JSON.stringify(err, Object.getOwnPropertyNames(err)));
        setSuccess("");
        setLoading(false);
      });
  };

  const handleChangePassword = () => {
    setError("");
    const jwt = getToken();
    fetch(`${FETCH_URL}users/changePassword`, {
      body: JSON.stringify({
        username: email,
        password: password,
        newPassword: newPassword,
      }),
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + jwt,
      },
      cache: "no-cache",
      credentials: "same-origin",
    })
      .then((response) => {
        if (response.status === 201) {
          setSuccess(
            "Contraseña cambiada exitosamente. Por favor recarga la página e inicie sesión."
          );
        }
      })
      .catch((err) => {
        setError(JSON.stringify(err, Object.getOwnPropertyNames(err)));
        setSuccess("");
        setLoading(false);
      });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#fff" }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "rgb(0,55,101)",
        }}
      >
          <img src={logo} alt="" height={150} />
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#fff",
          minHeight: "20vh",
          width: "100%",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={5}
          alignItems="center"
        >
          <Grid item>
            <img src={sgh} alt="" width={200} />
          </Grid>
          <Grid item>
            <img src={gmo} alt="" width={90} />
          </Grid>
          <Grid item>
            <img src={ray} alt="" width={200} />
          </Grid>
          <Grid item>
            <img src={lfm} alt="" width={200} />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#fff",
          minHeight: "40vh",
          width: "98vw",
        }}
      >
        <h1 style={{ color: "rgb(0, 55, 101)" }}>
          {isRecoveringPassword ? "Enviar correo de recuperación" : "Ingreso de usuarios"}
        </h1>
        <Grid container direction="column" xs={12} sm={4}>
          <TextField
            id="email"
            label="Correo electrónico *"
            variant="outlined"
            style={{ backgroundColor: "white", width: "100%", marginBottom: 20 }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="password"
            label="Contraseña *"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            style={{ backgroundColor: "white", width: "100%", marginBottom: 10, display: isRecoveringPassword ? "none" : "" }}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
            }}
          />
          {assignPasswordView ? <></> : (
          <Grid style={{ width: "100%", marginTop: 0, paddingTop: 0, marginBottom: 20}}>
            <p style={{ margin: 0, padding: 0, textAlign: "right"}}>
              {
              isRecoveringPassword ?
              <>¿Deseas iniciar sesión? Haz clic <span
                onClick={() => {setIsRecoveringPassword(false); setSuccess(""); setLoading(false);}}
                style={{ cursor: "pointer", color: "blue"}}
              >
                aquí
              </span></>
              : <>¿Olvidaste tu contraseña? Haz clic <span
                onClick={() => {setIsRecoveringPassword(true); setSuccess(""); setLoading(false);}}
                style={{ cursor: "pointer", color: "blue"}}
              >
                aquí
              </span></>}
            </p>
          </Grid>)}
          {assignPasswordView ? (
            <>
              <TextField
                id="newPassword"
                label="Nueva contraseña *"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                style={{ backgroundColor: "white", width: "100%", marginBottom: 20 }}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
              }}
              />
            </>
          ) : (
            <></>
          )}
        </Grid>
        {error ? (
          <Alert severity="error" style={{ marginBottom: 20 }}>
            {error}
          </Alert>
        ) : (
          <></>
        )}
        {success ? (
          <Alert severity="success" style={{ marginBottom: 20 }}>
            {success}
          </Alert>
        ) : (
          <></>
        )}
        {assignPasswordView ? (
          <Button
            variant="contained"
            style={{ backgroundColor: "rgb(0, 55, 101)" }}
            onClick={handleChangePassword}
          >
            Cambiar contraseña
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ backgroundColor: "rgb(0, 55, 101)" }}
            onClick={
              isRecoveringPassword ? handleSendRecoverEmail : handleLogin
            }
          >
            {isRecoveringPassword ? "Recuperar contraseña" : "Iniciar sesión"}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default Login;
