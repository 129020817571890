import { Grid, Paper } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const rulesForChargeColumns = [
  {
    field: "charge",
    name: "Cargo",
    type: "text",
  },
  {
    field: "sgh_sales_advisor",
    name: "Asesor de ventas",
    type: "bool",
  },
  {
    field: "sgh_sales_advisor_extended",
    name: "Asesor de ventas extendido",
    type: "bool",
  },
  {
    field: "sgh_management_boss",
    name: "Jefe de tienda",
    type: "bool",
  },
  {
    field: "sgh_simple_management_boss",
    name: "Bono de gestión (simple)",
    type: "bool",
  },
  {
    field: "sgh_management_zonal_boss",
    name: "Bono de gestión (zonal)",
    type: "bool",
  }
];

function ReglasPorCargo() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: "100%" }}>
      <Paper
        elevation={3}
        style={{
          padding: 25,
          paddingTop: 0,
          backgroundColor: "#DEEDF6",
        }}
      >
        <div style={{ height: 10 }} />
         <RulesTable
          title="Reglas por cargo"
          columns={rulesForChargeColumns}
          uri="sgh-rules-for-charge"
          downloadUri="sgh-rules-for-charge/file"
        />

        <div style={{ height: 10 }} />
      </Paper>
    </Grid>
  );
}

export default ReglasPorCargo;
