import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";
import UploadRegularFiles from "./UploadRegularFiles";
import Typography from '@mui/material/Typography';
import UploadUserAccounts from "./UploadUserAccounts";
import FilesList from "./FilesList"


const trans = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

export const trans_opuesto = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};

function UploadFiles() {
  const [month, setMonth] = useState("Octubre");
  const [year, setYear] = useState("2021");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [logs, setLogs] = useState("");
  const [expanded, setExpanded] = useState('panel1');

  const { getToken } = useTokenManager();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getLogs = async () => {
    const jwt = getToken();
    setLogs("Cargando...");

    fetch(
      `${FETCH_URL}logs?year=${year}&month=${trans_opuesto[month]}`,
      {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Authorization": "Bearer " + jwt
        }
      }
    ).then(raw => raw.json()).then((data) => {
      setLogs(data.log)
    }).catch(() => setLogs("No se encontraron logs para el periodo seleccionado. Si acaba de enviar los archivos, por favor espere a que se procesen."));
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={5}
    >
      <Grid container xs={6} style={{ paddingRight: 50 }}>
        <h1>Cargue de archivos</h1>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={5}
        >
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mes</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Mes"
                style={{ width: 200 }}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                  <MenuItem value={trans[i]}>{trans[i]}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Año</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="Año"
                style={{ width: 200 }}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                <MenuItem value={"2021"}>2021</MenuItem>
                <MenuItem value={"2022"}>2022</MenuItem>
                <MenuItem value={"2023"}>2023</MenuItem>
                <MenuItem value={"2024"}>2024</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ height: 30, width: 30 }}></div>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
          style={{ width: "100%", borderRadius: 15, marginBotton: 10 }}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography variant="h6"><strong>Archivo de cuentas de usuarios</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UploadUserAccounts
              year={year}
              month={month}
              setError={setError}
              setLoading={setLoading}
              setSuccess={setSuccess}
              loading={loading}
              setLogs={setLogs}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}
          style={{ width: "100%", borderRadius: 15, marginBotton: 10, marginTop: 12 }}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography variant="h6"><strong>Archivos de tiendas y empleados</strong></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UploadRegularFiles
              year={year}
              month={month}
              setError={setError}
              setLoading={setLoading}
              setSuccess={setSuccess}
              loading={loading}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container xs={6}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
        >
          <FilesList />
        </Grid>

        <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
          Registros del cargue de archivos:
        </Typography>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end">
          <TextareaAutosize
            minRows={20}
            maxRows={20}
            value={logs}
            disabled={true}
            style={{ width: "100%", overflowY: "auto" }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={getLogs}
            style={{ marginTop: 20 }}
          >
            Ver registros del periodo seleccionado
          </Button>
        </Grid>
        <Grid xs={12}>
          {error ? (
            <Alert severity="error" style={{ marginTop: 15 }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          ) : (
            <></>
          )}
          {success ? (
            <Alert severity="success" style={{ marginTop: 15 }}>
              <AlertTitle>Éxito</AlertTitle>
              {success}
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UploadFiles;
