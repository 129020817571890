import React, { createContext, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./commons/theme";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { AuthProvider } from "./modules/AuthProvider";

export const UtilsContext = createContext({
  role: undefined,
  jwt: undefined,
  enterprise: "gmo",
});

export function UtilsProvider({ children }) {
  let [enterprise, setEnterprise] = useState("gmo");
  let value = {
    enterprise,
    setEnterprise,
  };
  return (
    <UtilsContext.Provider value={value}>{children}</UtilsContext.Provider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <UtilsProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </UtilsProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
