import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import Button from '@mui/material/Button';
import useTokenManager from '../../hooks/useTokenManager';
import { FETCH_URL } from '../../config';
import { useAuth } from '../../hooks/useAuth';


export default function InconsistencyHistoryDialog({ open, setOpen, document, year, month }) {
    const [isFetchingTicket, setIsFetchingTicket] = React.useState(true);
    const [ticket, setTicket] = React.useState();
    const [description, setDescription] = React.useState("");
    const { getToken } = useTokenManager();
    const { isAdmin } = useAuth();

    const changeStatus = () => {
        const jwt = getToken();
        fetch(`${FETCH_URL}tickets?year=${ticket.year}&month=${ticket.month}&document=${ticket.employee.id_document}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + jwt
            },
            body: JSON.stringify({ "comment": description }),
        }).then(() => {
            handleClose();
        }).catch((error) => {
            console.error(error)
        })
    };

    React.useEffect(() => {
        // console.log("Se abrió el diálogo");
        if (!ticket && open && document && year && month) {
            // console.log(open, document, year, month)
            setIsFetchingTicket(true);
            const jwt = getToken();
            fetch(
                `${FETCH_URL}tickets/ticketHistory?year=${year}&month=${month}&document=${document}`,
                {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + jwt,
                    },
                }
            )
                .then((response) => response.json())
                .then((fetchedData) => {
                    // console.info("En INCONSISTENCY HISTORY DIALOG!");
                    // console.info(fetchedData);
                    if (fetchedData?.length) {
                        setTicket(fetchedData[0]);
                    }
                    // setOptometristConsultations(fetchedData);
                    setIsFetchingTicket(false);
                })
                .catch(error => console.error(error));
        }
    }, [open, document, year, month]);

    const handleClose = () => {
        setOpen(false);
        setDescription("");
        setTicket();
    };

    const watchers = React.useMemo(() => {
        if (ticket) return [
            ticket.bossMail,
            ticket.employeeMail
        ]
        else return [];
    }, [ticket]);

    if (isFetchingTicket) {
        return <Dialog open={open} onClose={handleClose}>
            <DialogContent style={{ padding: 50, paddingTop: 0, overflowX: "hidden", paddingBottom: 0 }}>
                <p>Cargando...</p>
            </DialogContent>
        </Dialog>
    }

    return (<Dialog open={open} onClose={handleClose}>
        {ticket && (<><DialogTitle>{ticket ? `Histórico de inconsistencia - Comisión ${ticket.employee.id_document}_${ticket.month}_${ticket.year}` : "Cargando..."}</DialogTitle>
            <DialogContent style={{ padding: 50, paddingTop: 0, overflowX: "hidden", paddingBottom: 0 }}>
                <strong>Observadores:</strong>
                <div style={{
                    width: "500px",
                    overflowWrap: "break-word",
                    paddingBottom: 25,
                    paddingTop: 10,
                    overflowX: "hidden"
                }}>
                    <Stack direction="row" spacing={1} style={{ width: "100%" }}>
                        {watchers.map(watcher => (<Chip label={watcher} variant="outlined" />))}
                    </Stack>
                </div>
                <p style={{ paddingTop: 0, marginTop: 0 }}><strong>Estado actual de la inconsistencia:</strong> {ticket.actualState ? "ABIERTA" : "CERRADA"}</p>
                <p style={{ paddingTop: 0, marginTop: 0 }}><strong>Histórico de cambios:</strong></p>
                <div style={{ marginTop: 20, maxHeight: 160 }}>
                    {
                        ticket.history.length ? (
                            <ul>
                                {ticket.history.map(history => (<p>
                                    <strong>{`El ${history.created_at.split("T")[0]}, ${history.author} cambió el estado a ${history.newState ? "ABIERTO" : "CERRADO"}:`}</strong> {history.comment}
                                </p>))}
                            </ul>
                        ) : (<p style={{margin: 0, padding: 0}}>
                            No se han registrado cambios mediante COMISUS para esta inconsistencia.
                        </p>)
                    }
                </div>
            </DialogContent>
        </>)}
        {(ticket && isAdmin) && (<>
            <DialogContent style={{ padding: 50, paddingTop: 0, overflowX: "hidden", paddingBottom: 0 }}>
                <div style={{ marginTop: 20 }}>
                    <Typography variant="h6">Cambio de estado a {ticket.actualState ? "CERRADO" : "ABIERTO"}:</Typography>
                    <TextField
                        id="name"
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder='Descripción del cambio de estado'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline={true}
                        minRows={4}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Regresar</Button>
                <Button onClick={changeStatus}>Cambiar estado</Button>
            </DialogActions>
        </>)}
        {(!ticket && !ticket) && (<DialogContent style={{ padding: 50, paddingTop: 0, overflowX: "hidden", paddingBottom: 0 }}><p>No se encontraron inconsistencias reportadas para esta comisión.</p></DialogContent>)}
    </Dialog>
    );
}