import { Grid, Paper } from "@material-ui/core";
import RulesTable from "../../../commons/components/RulesTable/RulesTable";

const rangesColumns = [
  {
    field: "variable_name",
    name: "Variable",
    type: "text"
  },
  {
    field: "greater_than",
    name: "Mayor que",
    type: "percentage",
  },
  {
    field: "lesser_than",
    name: "Menor que",
    type: "percentage",
  },
  {
    field: "result",
    name: "Resultado",
    type: "percentage",
  },
  {
    field: "with_goal",
    name: "Con meta",
    type: "bool"
  }
];

function Limites() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ width: "100%" }}>
      <Paper
        elevation={3}
        style={{
          padding: 25,
          paddingTop: 0,
          backgroundColor: "#EFFCEF",
        }}
      >
        <div style={{ height: 10 }} />
         <RulesTable
          title="Límites"
          columns={rangesColumns}
          uri="sgh-ranges"
          downloadUri="sgh-ranges/file"
        />
        <div style={{ height: 10 }} />
      </Paper>
    </Grid>
  );
}

export default Limites;
