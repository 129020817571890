// PROD
// export const FETCH_URL = "http://20.119.188.176/api/v1/";
// export const FETCH_URL = "https://v2c-gmo-comisiones.eastus2.cloudapp.azure.com/api/v1/";
export const FETCH_URL = "https://luxottica.comisus.com/api/v1/";

// DEV
// export const FETCH_URL = "http://20.15.118.7:3000/api/v1/";
// export const FETCH_URL = "https://test.comisus.com/api/v1/"
// export const FETCH_URL = "https://test.comisus.com/api/v1/"

// Local
// export const FETCH_URL = "http://192.168.10.10:3000/api/v1/"
