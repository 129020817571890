import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";


const columns = [
  {
    "field": "name",
    "name": "Nombres"
  },
  {
    "field": "lastname",
    "name": "Apellidos"
  },
  {
    "field": "email",
    "name": "Correo electrónico",
    "type": "email"
  },
  {
    "field": "password",
    "name": "Contraseña inicial",
    "type": "password"
  },
  {
    "field": "documentId",
    "name": "No. de documento"
  },
  {
    "field": "rol",
    "name": "Rol",
    "defaultValue": "admin",
    "disabled": true
  },
];

function NewAccountForm({ close, refresh }) {
  const [formData, setFormData] = useState({});
  const [loading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { getToken } = useTokenManager();

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const onValueChange = (key, value) => {
    // console.info("Cambiooo", key, value);
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const createNewUser = useCallback(() => {
    const newUser = {}
    const jwt = getToken();

    fetch(`${FETCH_URL}users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + jwt
      },
      body: JSON.stringify({
          "name": formData["name"],
          "lastname": formData["lastname"],
          "email": formData["email"],
          "password": formData.password,
          "documentId": formData["documentId"],
          "rol": "admin"
      }),
    }).then(() => {
      // console.info("sent!")
      close();
      refresh();
    }).catch((error) => console.error(error))
  }, [formData]);

    
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {columns.map((column, i) => {
          if (column.type === "password") {
            return (
              <Grid item style={{ margin: 15, width: 230 }}>
                <FormGroup>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.password}
                  onChange={(event) =>
                    onValueChange(
                      "password",
                      event.target.value
                    )
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Contraseña"
                  variant="filled"
                />
                </FormGroup>
              </Grid>
            );
          } else {

            return (
              <Grid item style={{ margin: 15, width: 230 }}>
                <TextField
                  fullWidth
                  id={column.field}
                  type={column.type ?? "text"}
                  label={column.name}
                  variant="filled"
                  value={formData[column.field]}
                  defaultValue={column.defaultValue}
                  disabled={column.disabled}
                  onChange={(event) =>
                    onValueChange(
                      event.target.id,
                      event.target.value
                    )
                  }
                />
              </Grid>
            );
          }
        })}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item style={{ marginTop: 15 }}>
          <Button
            onClick={createNewUser}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default NewAccountForm;
