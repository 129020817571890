import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTable from "../../commons/components/CustomTable/CustomTable";
import { FETCH_URL } from "../../config";
import useTokenManager from "../../hooks/useTokenManager";

function EmployeesList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { getToken } = useTokenManager();

  const fetchData = () => {
    setLoading(true);
    const jwt = getToken();
    fetch(`${FETCH_URL}employees`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
        "Accept-Encoding": "gzip, deflate, br",
        Authorization:
          "Bearer " + jwt,
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((fetchedData) => {
        const parsedData = fetchedData.map((employee) => {
          let parsedStoreCodes = [];
          employee.store_codes.forEach((storeCode) => {
            parsedStoreCodes.push(storeCode.gesti_code);
          });
          return { ...employee, store_codes: parsedStoreCodes.join(",") };
        });
        setData(parsedData);
        setLoading(false);
      });
  };

  useEffect(() => fetchData(), []);

  const columns = [
    { id: "id_document", label: "Documento", desiredWidth: 70 },
    { id: "lastname_name", label: "Nombre", desiredWidth: 300 },
    { id: "actual_postition", label: "Cargo actual", desiredWidth: 150 },
    { id: "type_boss", label: "Tipo de jefe", desiredWidth: 300 },
    { id: "type_advisor", label: "Tipo de asesor" },
    { id: "type_optometrist", label: "Tipo optómetra" },
    { id: "working_day", label: "Día de trabajo" },
  ];

  return (
    <Grid>
      <h1>Empleados</h1>
      <CustomTable
        columns={columns}
        data={data}
        error={error}
        loading={loading}
      />
    </Grid>
  );
}

export default EmployeesList;
